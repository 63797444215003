const initialState = {
    availableArenas: [],
    enrolledArenas: [],
    currentArena: [],
    currentRoadmapQuestions : []
};

const arenaReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_AVAILABLE_ARENAS':
            return {
                ...state,
                availableArenas: action.payload
            };
        case 'SET_ENROLLED_ARENAS':
            return {
                ...state,
                enrolledArenas: action.payload
            }
        case 'SET_CURRENT_ARENA':
            return {
                ...state,
                currentArena: action.payload
            }
        case 'SET_ROADMAP_QUESTIONS':
            return {
                ...state,
                currentRoadmapQuestions : action.payload
            }    
        default:
            return state;
    }
};

export default arenaReducer;