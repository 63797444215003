import { Tooltip } from '@mui/material'
import React from 'react'
import { fetchQuestion } from '../../utils/apis'
import { useNavigate } from 'react-router-dom'
import ToastError from '../toasts/ToastError'
import { toast } from 'react-toastify'
import { toastConfig } from '../../utils/constants'
import LockingBar from '../ui/LockingBar'

const LevelCard = ({ level, imgUrl, isLimitOver, remainingQuestions, totalQuestions, lockPosition, levelCompletedPercentage, tool, setShowModal, desc, isSubscribed }) => {
  const navigate = useNavigate()
  const handleClick = async () => {
    setShowModal(true);

    // Show toast and close modal if limit is over
    if (isLimitOver) {
      toast(<ToastError message={"Please Subscribe to Start Your Journey"} />, toastConfig);
      navigate("/pricing")
      setShowModal(false);
      return;
    }

    try {
      const queryParams = new URLSearchParams({
        questionId: "",
        toolName: tool,
        level: level,
        func: "",
        isInitialLoad: false,
        toNext: false,
        toPrev: false,
        isLevelUpdated: true,
        isToolUpdated: true,
        isFuncUpdated: false,
      });

      const controller = new AbortController();
      console.log(queryParams.toString());

      // Fetch the question
      const response = await fetchQuestion(queryParams, controller.signal);

      console.log(response, "__RESPONSE");

      if (response.status === 200) {
        navigate(`/question/?questionId=${response.data.questionId}`);
        setShowModal(false);
        window.location.reload()
        return; // Exit function early
      }

      // Handle error cases
      const errorMessage = response?.message || "Something went wrong!";
      toast(<ToastError message={errorMessage} />, toastConfig);

    } catch (err) {
      console.error(err);
      toast(<ToastError message={err?.message || "An error occurred!"} />, toastConfig);
    } finally {
      setShowModal(false); // Always hide the modal after any outcome
    }
  };

  return (
    // <div className={`w-60 h-auto bg-[#323439] p-4 flex flex-col gap-4 rounded-2xl ${isLimitOver && "relative after:absolute after:z-10 after:w-24 after:h-16 after:content[''] after:bg-yellow-900 after:-right-4 after:top-0 after:z-[10] after:rounded-full after:blur-lg overflow-hidden border-yellow-800 border border-opacity-50 bg-[#202124]"} cursor-pointer`}>
    //   <img
    //     src={imgUrl}
    //     alt="Level Image"
    //     className="h-48 w-full object-cover rounded-xl shadow-lg z-50"
    //   />
    //   <div className="flex flex-col gap-4 text-white">
    //     <div className="flex flex-row justify-between items-center">
    //       <div className="flex flex-col">
    //         <span className="text-xl font-semibold">{`Level ${level}`}</span>
    //       </div>
    //       <Tooltip
    //         title={`You have ${remainingQuestions} free question${remainingQuestions !== 1 ? 's' : ''} remaining out of ${totalQuestions} total questions.`}
    //         arrow
    //       >
    //         <span className="font-semibold !text-yellow-400 cursor-pointer transition duration-300">
    //           {remainingQuestions}/{totalQuestions}
    //         </span>
    //       </Tooltip>
    //     </div>

    //     <LockingBar lockPosition={lockPosition} levelCompletedPercentage={levelCompletedPercentage} />

    //     {/* Action Button */}
    //     {isLimitOver ? (
    //       <button
    //         className="group flex justify-center items-center gap-2 group-hover:before:duration-500 group-hover:after:duration-500 after:duration-500 hover:border-neutral-900 duration-500 hover:duration-500 origin-left hover:decoration-2 hover:text-neutral-300 relative bg-neutral-900 px-2 py-3 border text-left p-3 text-gray-50 text-base font-semibold rounded-lg overflow-hidden after:absolute after:z-10 after:w-12 after:h-16 after:content[''] after:bg-yellow-900 after:-left-4 after:top-8 after:rounded-full after:blur-lg hover:after:animate-pulse" onClick={handleClick}
    //       >
    //         Subscribe to Unlock
    //       </button>
    //     ) : (
    //       <button
    //         className="group flex justify-center items-center gap-2 group-hover:before:duration-500 group-hover:after:duration-500 after:duration-500 hover:border-neutral-900 duration-500 hover:duration-500 origin-left hover:decoration-2 hover:text-neutral-300 relative bg-neutral-900 px-2 py-3 border text-left p-3 text-gray-50 text-base font-semibold rounded-lg overflow-hidden after:absolute after:z-10 after:w-12 after:h-12 after:content[''] after:bg-sky-900 after:-left-8 after:top-8 after:rounded-full after:blur-lg hover:after:animate-pulse" onClick={handleClick}
    //       >
    //         Start Solving
    //       </button>
    //     )}

    //   </div>
    // </div>
    <div className="bg-[#2c2f30] text-white rounded-xl p-6 w-64 relative overflow-hidden shadow-md flex flex-col justify-between">
      {/* Icon */}
      <div className="bg-[#6A4C8C] p-3 rounded-lg mb-4 w-10 h-10 flex items-center justify-center font-bold">
        {level}
      </div>

      {/* Description */}
      <p className="text-sm mb-2 overflow-hidden text-ellipsis line-clamp-2 text-gray-300">
        {desc?.toString().split(',').join(', ')}
      </p>

      {
        !isSubscribed &&
        <div className="flex items-center mb-3 gap-1">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="1em"
            height="1em"
            fill="currentColor"
            className="text-yellow-400 h-5 w-5"
            style={{
              left: `${lockPosition}%`,
            }}
          >
            <path
              fill-rule="evenodd"
              d="M7 8v2H6a3 3 0 00-3 3v6a3 3 0 003 3h12a3 3 0 003-3v-6a3 3 0 00-3-3h-1V8A5 5 0 007 8zm8 0v2H9V8a3 3 0 116 0zm-3 6a2 2 0 100 4 2 2 0 000-4z"
              clip-rule="evenodd"
            ></path>
          </svg>
          <h2 className={`text-sm font-bold ${remainingQuestions === 0 && "text-red-500"}`}>
            {remainingQuestions}/{totalQuestions}
          </h2>
        </div>
      }

      {/* Abstract Elements */}
      <div className="absolute -bottom-8 -right-10 w-32 h-32">
        <svg xmlns="http://www.w3.org/2000/svg" width="200" height="200" fill="none" viewBox="0 0 200 200">
          <path fill="url(#paint0_linear_105_379)" d="M165.963 134.037c-5.467 5.467-14.332 5.467-19.799 0l-24.137-24.138c-5.468-5.467-5.468-14.331 0-19.799l24.137-24.137c5.467-5.467 14.332-5.467 19.799 0L190.101 90.1c5.467 5.468 5.467 14.332 0 19.799l-24.138 24.138Zm-112.127 0c-5.467 5.467-14.332 5.467-19.8 0L9.9 109.899c-5.468-5.467-5.468-14.331 0-19.799l24.137-24.137c5.467-5.467 14.332-5.467 19.799 0L77.973 90.1c5.468 5.468 5.468 14.332 0 19.799l-24.137 24.138ZM109.9 190.1c-5.468 5.468-14.332 5.468-19.8 0l-24.137-24.137c-5.467-5.467-5.467-14.332 0-19.799l24.138-24.137c5.467-5.468 14.331-5.468 19.799 0l24.137 24.137c5.467 5.467 5.467 14.332 0 19.799L109.9 190.1Zm0-112.127c-5.468 5.468-14.332 5.468-19.8 0L65.963 53.836c-5.467-5.468-5.467-14.332 0-19.8L90.101 9.9c5.467-5.467 14.331-5.467 19.799 0l24.137 24.138c5.467 5.467 5.467 14.331 0 19.799L109.9 77.973Z"></path>
          <defs><linearGradient id="paint0_linear_105_379" x1="154.166" x2="47.248" y1="35.943" y2="144.745" gradientUnits="userSpaceOnUse"><stop offset=".051" stop-color="#FFB6E1"></stop><stop offset="1" stop-color="#FBE3EA"></stop></linearGradient></defs>
        </svg>
      </div>

      {/* Button */}
      <button className={`px-4 py-2 rounded-md text-sm font-medium transition-all duration-300 ${isLimitOver ? 'bg-gradient-to-r from-pink-500 to-purple-600 hover:from-purple-600 hover:to-pink-500' : 'bg-gray-700 hover:bg-gray-600'} w-max`}
        onClick={handleClick}
      >
        {isLimitOver ? 'Subscribe Now' : 'Solve Now'}
      </button>
    </div>


  )
}

export default LevelCard