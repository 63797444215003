import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import "../styles.css"
import { fetchArenaQuestion, validateAnswer, runQuery, fetchUpdatedToken } from "../utils/apis"
import { toast } from "react-toastify"
import QuestionComponent from "../assets/question"
import SolutionUploadComponent from "../assets/solution-upload"
import { useDispatch, useSelector } from "react-redux"
import { fetchUserDetails } from "../redux/actions/user"
import { useNavigate, useSearchParams, useParams ,useLocation} from "react-router-dom"
import ArenaQuestionHeader from "./arena-question-header2"
import { toastConfig } from "../utils/constants"
import ResultComponent from "../assets/result"
import Modal from "../assets/modal"
import { SUBJECTS, LANGUAGES } from "../utils/constants"
import { Button, MenuItem } from "@mui/material"
import useNetworkStatus from "../assets/network_detector"
import SplitPane, { Pane } from "split-pane-react"
import "split-pane-react/esm/themes/default.css"
import { SkipNext, SubjectOutlined } from "@mui/icons-material"
import Dropdown from "./ui/Dropdown"
import QuestionTimer from "../assets/timer"
import QuestionPageSkeleton from "./skeleton/QuestionPageSkeleton.jsx"
import { fetchUserMetrics } from "../redux/actions/usermetrics.js"
import RewardPopup from "../components/RewardPoint/RewardPopup.jsx"
import { fetchCurrentArenaData, fetchCurrentRoadmapQuestions } from "../redux/actions/arena.js"
import ToastInfo from "./toasts/ToastInfo.js"
import ToastError from "./toasts/ToastError.js"

const ArenaQuestionPage = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { name, roadmap } = useParams()

  const { userDetails, currentArena } = useSelector(
    useCallback(
      (state) => ({
        userDetails: state.user.userDetails,
        currentArena: state.arena.currentArena,
      }),
      []
    )
  )
  const [loader, setLoader] = useState(false)
  //const [isInitialLoad, setIsInitialLoad] = useState(true)

  const initialModal = {
    open: false,
    message: "",
    title: "Confirm Action",
    action: "",
  }
  const [confirmationModal, setConfirmationModal] = useState(initialModal)
  const [mode, setMode] = useState(LANGUAGES[0].key)
  const [questionMeta, setQuestionMeta] = useState(null)

  const [preferences, setPreferences] = useState({
    tool: "",
  })

  const [searchParams, setSearchParams] = useSearchParams()
  const [isInitialLoad, setIsInitialLoad] = useState(true)

  const initialResultSection = useMemo(
    () => ({
      submittedAnswer: "",
      answerResult: "",
      isError: false,
      result: null,
      submittedKey: "",
      resultLoader: false,
      runSol: false,
      countOfRows: 0,
      summaryContent: "",
    }),
    []
  )

  const [resultSection, setResultSection] = useState(initialResultSection)

  const initialPendingStates = useMemo(
    () => ({
      tool: "",
      next: false,
      prev: false,
      exactQuestionId: "",
    }),
    []
  )
  const [pendingUpdates, setPendingUpdates] = useState(initialPendingStates)

  const [showSuccessPage, setShowSuccessPage] = useState(false)
  const [quesFetchError, setQuesFetchError] = useState(false)
  const [isTimerPaused, setIsTimerPaused] = useState(false)
  const [subscribeComponent, setSubscribeComponent] = useState(false)
  const [showFreeArenaPopup, setShowFreeArenaPopup] = useState(false)
  const isAnswerSubmitted = false
  const { isOnline, isNetworkActive } = useNetworkStatus()
  const [showNetworkConn, setShowNetworkConn] = useState(false)

  const timer = useRef(0)
  const answerRewardPoints = useRef(null)
  const fetchingQuestion = useRef(false)
  const location = useLocation();
  const { status, parentQuestionId } = location.state || {};

  useEffect(() => {
    if (!isNetworkActive) {
      setShowNetworkConn(true)
    } else {
      setShowNetworkConn(false)
    }
  }, [isNetworkActive])

  useEffect(() => {
    const interval = setInterval(() => {
      console.log("refreshing token")
      const refreshToken = localStorage.getItem("refreshToken")
      if (refreshToken) {
        fetchUpdatedToken().then((data) => {
          if (data.error) {
            toast.error(data.error, toastConfig)
          } else {
            localStorage.setItem("usertoken", data.token)
          }
        })
      }
    }, 300000)
    return () => clearInterval(interval)
  }, [])

  const confirmUpdate = async (confirm) => {
    const action = confirmationModal.action
    setConfirmationModal((prevState) => ({ ...prevState, open: false }))

    if (confirm) {
      switch (action) {
        case "toolUpdate":
          setPreferences((prevState) => ({ ...prevState, tool: pendingUpdates.tool }))
          break
        case "next":
        case "previous":
        case "exactQuestionSwitch":
          setPreferences((prevState) => ({ ...prevState }))
          break
        default:
          break
      }
    } else {
      setPendingUpdates(initialPendingStates)
    }
  }

  useEffect(() => {
    dispatch(fetchUserDetails())
    dispatch(fetchCurrentArenaData(name))
  }, [dispatch])

  useEffect(() => {
    if (userDetails && currentArena && !quesFetchError && isInitialLoad) {
      if (!userDetails.isVerified) {
        toast.error("Please verify your email to continue", toastConfig)
        navigate("/verify-email")
      }

      const hasShownPopup = localStorage.getItem("hasShownFreeArenaPopup")
      if (userDetails.loginCount === 1 && hasShownPopup !== "true") {
        setShowFreeArenaPopup(true)
        localStorage.setItem("hasShownFreeArenaPopup", "true")
      }
      if (currentArena){
        const currentRoadmap = currentArena.roadmaps.filter((roadmapItem) => roadmapItem.name === roadmap)[0]
        setPreferences({ tool: currentRoadmap?.tool ||  "SQL" })
      }else {
        setPreferences({tool : currentArena?.tool || 'SQL'})
      }
      
    }
  }, [userDetails, currentArena, quesFetchError, isInitialLoad])

  useEffect(() => {
    if (!fetchingQuestion.current && preferences.tool.length > 0) {
      fetchingQuestion.current = true
      setLoader(true)
      fetchMetaData()
    }
  }, [preferences])

  const fetchMetaData = async () => {
    setLoader(true)

    try {
      await getQuestionMetaData()
      if (isInitialLoad) setIsInitialLoad(false)
    } catch (error) {
      console.error("Error fetching metadata:", error)
      setQuesFetchError(true)
    } finally {
      fetchingQuestion.current = false
    }
  }

  const resetComponent = useCallback(() => {
    setPendingUpdates(initialPendingStates)
    setQuestionMeta(null)
    setResultSection(initialResultSection)
    setShowSuccessPage(false)
    setIsTimerPaused(false)
    timer.current = 0
    answerRewardPoints.current = null
  }, [])

  async function getQuestionMetaData() {
    if (!preferences.tool) return

    let questionId
    questionId = pendingUpdates.exactQuestionId ? pendingUpdates.exactQuestionId : searchParams.get("questionId") || ""

    try {
      const queryParams = new URLSearchParams({
        questionId,
        toolName: preferences.tool,
        isInitialLoad,
        toNext: pendingUpdates.next,
        toPrev: pendingUpdates.prev,
        name,
        arenaId: currentArena._id,
        roadmap,
        status,
        parentQuestionId : parentQuestionId ||  questionMeta?.parentQuestionId
      })

      resetComponent()

      let response = await fetchArenaQuestion(queryParams)

      if (response.status === 200) {
        setQuestionMeta(response.data)
        navigate(`?questionId=${response.data.questionId}`, { replace: true })

        if (response.data?.answerSubmitted?.length && response.data.rewardPoints) {
          toast(<ToastInfo message={`You have already submitted your solution & scored ${response.data.rewardPoints} reward points, however you can practice this problem as many times as you wish`}/>, toastConfig)
        }
        if (preferences.tool === "Python") {
          setResultSection((prevState) => ({ ...prevState, submittedAnswer: response.data.solutionTemplate }))
        }
        if (response.data.rewardPoints) {
          setIsTimerPaused(true)
        }
        timer.current = response.data.totalTimeSpent || 0
      } else {
        toast.error(response.message || "Some error has occurred", toastConfig)

        setIsTimerPaused(true)
        if (response.message === "No question found") {
          setLoader(false)
        }
        if (response.message === "Free tier complete") {
          setSubscribeComponent(true)
          setLoader(false)
        }
        setQuesFetchError(true)
      }
    } catch (error) {
      console.error(error)
      setQuesFetchError(true)
      toast.error("Some error has occurred", toastConfig)
    } finally {
      setLoader(false)
      dispatch(fetchUserMetrics())
      dispatch(fetchCurrentRoadmapQuestions(currentArena.name, roadmap, ''))
    }
  }
  const handleSolution = useCallback((value) => {
    setResultSection((prevState) => ({ ...prevState, submittedAnswer: value }))
  }, [])

  const getValidateObj = (key, ans) => {
    const formData = new FormData()
    const answer = ans || resultSection.submittedAnswer
    let { tool } = preferences
    const { questionId, userQuestionHistoryId, ExcelFunctions, SQLFunctions, PythonFunctions } = questionMeta
    const functionsMap = {
      Excel: ExcelFunctions,
      SQL: SQLFunctions,
      Python: PythonFunctions,
    }

    formData.append(tool === "Excel" ? "file" : "submittedAnswer", answer)
    formData.append("questionId", questionId)
    formData.append("techStack", tool)
    formData.append("language", LANGUAGES.find((l) => l.key === mode).value)
    formData.append("userQuestionHistoryId", userQuestionHistoryId)
    formData.append("submittedKey", key)
    formData.append("arenaId", currentArena._id)
    formData.append("arenaName", name)
    formData.append("roadmapName", roadmap)

    if (functionsMap[tool]) {
      formData.append(`${tool}Functions`, functionsMap[tool])
    }
    if (key === "submit") {
      formData.append("insightsSummary", resultSection.summaryContent)
      formData.append("timeSpent", timer.current)
    }

    return formData
  }

  const updateResultSection = (response, key) => {
    let isAnswerCorrect = false
    console.log({ response })
    if (response.status === 200) {
      if (response.data.isAnswerCorrect) {
        setResultSection((prevState) => ({
          ...prevState,
          answerResult: true,
          result: response.data.submittedResult,
          resultLoader: false,
          countOfRows: response.data.count,
        }))
        isAnswerCorrect = true
        localStorage.removeItem(`${questionMeta.userQuestionHistoryId}-solution`)
      } else {
        setResultSection((prevState) => ({ ...prevState, isError: false, result: key === "submit" ? response.message : response.data.submittedResult, resultLoader: false, countOfRows: response.data.count }))
      }
    } else {
      setResultSection((prevState) => ({ ...prevState, isError: true, result: response.message || "Some error occurred", resultLoader: false }))
    }

    if (isAnswerCorrect) answerRewardPoints.current = response.rewardPoints

    if (key === "run" && !questionMeta.rewardPoints > 0) {
      setIsTimerPaused(false)
    }
    if (key === "submit") {
      setShowSuccessPage(true)
      setIsTimerPaused(isAnswerCorrect)
      if (isAnswerCorrect) {
        isAnswerSubmitted = true
      }
    }
  }
  const onSubmit = useCallback(
    async (key, answer, summaryContent) => {
      try {
        if (!answer) {
          toast(<ToastError message ={"Please add the solution"}/>, toastConfig)
          setIsTimerPaused(false)
          return
        } else if (key === "submit" && !summaryContent) {
          toast(<ToastError message ={"Please add the summary"}/>, toastConfig)
        } else {
          setIsTimerPaused(true)
          setResultSection((prevState) => ({
            ...prevState,
            submittedAnswer: answer,
            resultLoader: true,
            runSol: key === "run" ? true : prevState.runSol,
            answerResult: false,
            summaryContent: summaryContent,
          }))
          let formData = getValidateObj(key, answer)
          let response = key === "submit" ? await validateAnswer(formData) : await runQuery(formData)

          updateResultSection(response, key)
        }
      } catch (error) {
        setResultSection((prevState) => ({ ...prevState, isError: true, result: error.message || "Some error occurred" }))
      }
    },
    [getValidateObj, updateResultSection]
  )

  const onChangeTechStack = useCallback(
    async (t) => {
      const currQuestionId = searchParams.get("questionId")

      setPendingUpdates((prevState) => ({ ...prevState, exactQuestionId: currQuestionId, tool: t }))
      setPreferences((prevState) => ({ ...prevState, tool: t }))
    },
    [questionMeta]
  )
  const onChange = useCallback(
    async (action, ...args) => {
      const [targetValue, exactTool, exactLevel] = args
      const runStatus = resultSection.runSol
      const messages = {
        toolUpdate: runStatus ? `You've already run the solution. Are you sure you want to switch to the ${targetValue} tool and reset your progress on this question?` : `Switching to the ${targetValue} tool will skip the current question. Are you sure?`,
        next: runStatus ? `Try submitting your solution before moving to the next question. Ready to proceed?` : `Advance to the next question? Unsaved work will be lost.`,
        previous: runStatus ? `It seems you’ve made progress. Consider submitting before going back. Still want to switch?` : `Are you sure you want to revisit the previous question?`,
        exactQuestionSwitch: runStatus ? `You’re about to switch to a specific question, which will reset your current progress. Continue?` : `Switching questions will discard your progress here. Ready to switch?`,
      }

      const currPendingUpdates = {
        toolUpdate: { tool: targetValue },
        next: { next: true },
        previous: { prev: true },
        exactQuestionSwitch: {
          exactQuestionId: targetValue,
        },
      }

      setPendingUpdates((prevState) => ({ ...prevState, ...currPendingUpdates[action] }))
      setConfirmationModal((prevState) => ({
        ...prevState,
        message: messages[action],
        open: true,
        action: action,
      }))
    },
    [resultSection.runSol, preferences.tool, pendingUpdates.tool]
  )

  const onModalClose = async () => {
    setConfirmationModal({ ...initialModal })
    setPendingUpdates({ ...initialPendingStates })
  }

  const [sizes, setSizes] = useState([400, "40%"])

  const inlineStyle = useMemo(
    () => ({
      display: "flex",
      height: "calc(100vh - 89px)",
      marginTop: "90px",
      backgroundColor: "rgba(35, 38, 39, 1)",
    }),
    []
  )

  const inlineStyleLoading = useMemo(
    () => ({
      overflow: "auto",
      height: "calc(100vh - 50px)",
      width: "100%",
      marginTop: "50px",
      backgroundColor: "rgba(35, 38, 39, 1)",
      position: "relative",
      color: "white",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    }),
    []
  )

  const onTimeUpdate = useCallback((updatedTime) => {
    timer.current = updatedTime
  }, [])

  const handleSuccessNext = useCallback((value) => {
    if(!questionMeta?.isLastQuestion || !questionMeta?.isFirstQuestion){
    setPendingUpdates((prevState) => ({ ...prevState, next: true }))
    setPreferences((prevState) => ({ ...prevState }))
    }
    else {
      if (questionMeta?.isFirstQuestion) <ToastInfo message={`You're in the initial stage of roadmap`}/>
      else if(questionMeta?.isLastQuestion) <ToastInfo message = {`You've reached the end of roadmap`}/>
    }

  }, [])

  console.log(questionMeta, "Question Meta")

  return (
    <div>
      <ArenaQuestionHeader
        primaryTool={preferences.tool}
        onSubmit={onSubmit}
        handleQuesSwitch={(value) => onChange(value)}
        handleSwitchtoExactQues={(...args) => onChange("exactQuestionSwitch", ...args)}
        stopTimer={() => setIsTimerPaused(true)}
        startTimer={() => setIsTimerPaused(false)}
        questionMeta={questionMeta}
        
      />
      {loader ? (
        <div style={inlineStyleLoading}>
          <QuestionPageSkeleton />
        </div>
      ) : (
        <>
          {!questionMeta ? (
            <div style={inlineStyleLoading}>
              <div className="flex flex-col justify-start items-center">
                {quesFetchError && (
                  <>
                    <div className="mx-auto my-4">
                      <h2 className="font-bold text-3xl text-gray-200">Unable to fetch current question</h2>
                      <div className="gradient" />
                    </div>
                    <div className="shiny-border max-w-md">
                      <div className="myCard p-6 !bg-[#1f2021]">
                        <Button
                          variant="outlined"
                          onClick={() => onChange("next")}
                          className={`!flex-1  !shadow-none  !text-yellow-600 !border-yellow-600 !my-4 !ml-auto`}
                          size="small"
                          endIcon={<SkipNext />}>
                          Switch to Next Question
                        </Button>
                        
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          ) : questionMeta ? (
            <div>
              <div>
                <SplitPane
                  split="vertical"
                  sizes={sizes}
                  onChange={setSizes}
                  style={inlineStyle}>
                  <Pane
                    minSize={500}
                    maxSize="50%">
                    <div className="question-data-wrapper">
                      <QuestionComponent
                        questionMeta={questionMeta}
                        techStack={preferences.tool}
                        onSubmit={onSubmit}
                        isQuestionFetched={questionMeta ? true : false}
                        onChangeTechStack={onChangeTechStack}
                        techStacks={questionMeta?.techStacks}
                        primaryTool={preferences.tool}
                        userDetails={userDetails}
                      />
                    </div>
                  </Pane>
                  <div className="question-data-wrapper">
                    <SolutionUploadComponent
                      tool={preferences.tool}
                      handleFileUpload={handleSolution}
                      handleTextInput={handleSolution}
                      answer={resultSection.submittedAnswer}
                      summaryContent={resultSection.summaryContent}
                      handleModeChange={(value) => {
                        setMode(value)
                      }}
                      LANGUAGES={LANGUAGES}
                      mode={mode}
                      onSubmit={onSubmit}
                      onChangeTechStack={onChangeTechStack}
                      techStacks={questionMeta?.techStacks}
                      selectedTechStack={preferences.tool}
                      isQuestionFetched={questionMeta ? true : false}
                      primaryTool={preferences.tool}
                      questionMeta={questionMeta}
                      timer={timer.current}
                      stopTimer={() => setIsTimerPaused(true)}
                      startTimer={() => setIsTimerPaused(false)}
                      resultLoader={resultSection.resultLoader}
                      isArena={window.location.href.includes("arena-question") ? true : false}
                      handleCurrentAnswer={() => setResultSection((prevState) => ({ ...prevState, submittedAnswer: preferences.tool === "SQL" ? "" : questionMeta.solutionTemplate }))}>
                      <QuestionTimer
                        userQuestionHistoryId={questionMeta.userQuestionHistoryId}
                        elapsedTime={timer.current}
                        isTimerPaused={isTimerPaused}
                        onTimeUpdate={onTimeUpdate}
                        onQuestionSwitch={loader && !isInitialLoad}
                        isAnswerSubmitted={isAnswerSubmitted}
                      />
                    </SolutionUploadComponent>

                    <ResultComponent
                      result={resultSection.result}
                      isAnswerCorrect={resultSection.answerResult}
                      resultLoader={resultSection.resultLoader}
                      submittedKey={resultSection.submittedKey}
                      countOfRows={resultSection.countOfRows}
                    />
                  </div>
                </SplitPane>
              </div>
            </div>
          ) : null}
        </>
      )}
      {confirmationModal.open && confirmationModal.message && (
        <Modal
          isOpen={confirmationModal.open}
          onClose={onModalClose}
          title={confirmationModal.title}>
          <p className="text-gray-400 text-sm mt-2">{confirmationModal.message}</p>
          <div className="flex gap-2 mt-4">
            <Button
              variant="contained"
              size="small"
              className="!shadow-none !bg-gray-600 !text-gray-200 !border-0"
              onClick={() => confirmUpdate(true)}>
              Yes
            </Button>
            <Button
              variant="outlined"
              size="small"
              className="!shadow-none !text-gray-200 !border !border-gray-600"
              onClick={() => confirmUpdate(false)}>
              No
            </Button>
          </div>
        </Modal>
      )}
      {showFreeArenaPopup && (
        <Modal
          isOpen={showFreeArenaPopup}
          onClose={() => setShowFreeArenaPopup(false)}
          title="Welcome to Marma AI!">
          <p className="text-gray-400 text-sm mt-2">Hi {userDetails.name}, We are pleased to offer you free access to Marma Arena. You can explore it in the Arenas section. Enjoy your learning journey with Marma AI.</p>
        </Modal>
      )}
      {showNetworkConn && (
        <Modal
          isOpen={showNetworkConn}
          onClose={() => setShowNetworkConn(false)}
          title="Check your Internet connection">
          <p className="text-gray-400 text-sm mt-2">Reconnect to submit/run your solutions</p>
        </Modal>
      )}
      {subscribeComponent && (
        <Modal
          isOpen={subscribeComponent}
          onClose={() => setSubscribeComponent(!subscribeComponent)}
          title="Free trial Complete!">
          <p className="text-gray-200 text-md mt-2 font-semibold">Your Data Journey Doesn't Stop Here - Unlock More Challenges and Mastery with Premium!</p>
          <div className="flex gap-2 mt-4">
            <Button
              variant="contained"
              size="small"
              className="!shadow-none !bg-green-600 !text-gray-200 !border-0"
              onClick={() => navigate("/pricing")}>
              Try Premium
            </Button>
            <Button
              variant="outlined"
              size="small"
              className="!shadow-none !text-gray-200 !border !border-gray-600 !ms-4"
              onClick={() => setSubscribeComponent(!subscribeComponent)}>
              Try Later
            </Button>
          </div>
        </Modal>
      )}
      {showSuccessPage && (
        <Modal
          isOpen={showSuccessPage}
          onClose={() => setShowSuccessPage(false)}
          title={null}>
          <RewardPopup
            isCorrect={resultSection.answerResult}
            getQuestionMetaData={handleSuccessNext}
            setShowSuccessPage={setShowSuccessPage}
            rewardPoints={answerRewardPoints.current}></RewardPopup>
        </Modal>
      )}
    </div>
  )
}

export default ArenaQuestionPage
