import React, { useState, useEffect } from "react"
import { getSubscriptionInfo } from "../utils/apis" // Import the API functions
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import Loader from "./loader"
import { noSubscription } from "./image_files"

const SubscriptionsOrders = () => {
  const [subscriptions, setSubscriptions] = useState(null)

  const [isEditable, setIsEditable] = useState(false)
  const [isError, setIsError] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      const subscriptionsData = await getSubscriptionInfo() // Fetch subscriptions data
      if (subscriptionsData.status === 200) {
        setSubscriptions(subscriptionsData?.data ?? [])
      } else {
        setIsError(true)
      }
    }

    fetchData()
  }, [])

  const handleEditClick = () => {
    setIsEditable(!isEditable)
  }

  const handleChange = (event, index, type) => {
    const { name, value } = event.target
    if (type === "subscriptions") {
      const newSubscriptions = [...subscriptions]
      newSubscriptions[index][name] = value
      setSubscriptions(newSubscriptions)
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    //await updateSubscriptions(subscriptions) // Update subscriptions data via API
    //await updateOrders(orders) // Update orders data via API
    //setIsEditable(false)
  }

  const handleDate = (dateString) => {
    const dateObject = new Date(dateString)

    const localDateTime = dateObject.toLocaleString("en-US", {
      month: "short",
      day: "numeric",
      year: "numeric",
    })

    return localDateTime
  }

  return !subscriptions && !isError ? (
    <Loader />
  ) : (
    <div className="animate__animated animate__fadeIn">
      <div className=" mb-4">
        <div className="flex justify-between sticky   items-baseline rounded-[18px] z-20">
          <h2 className="font-semibold text-[#e5e7eb] text-xl">Arena Subscriptions</h2>
        </div>
      </div>
      {isError ? (
        <div className="flex flex-col justify-center items-center h-full">
          <img
            src={noSubscription}
            alt="No Data Found"
            className="w-[250px] mb-3 opacity-40"
          />
          <h2 className="text-base text-gray-400">No active subscriptions!</h2>
        </div>
      ) : (
        <div className="shiny-border">
          <div className="myCard p-6 !bg-[#1f2021]">
            {subscriptions && (
              <TableContainer className="!max-w-full">
                <Table
                  size="small"
                  aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <TableCell className="!text-[#e5ebe7] !border-collapse">Type </TableCell>
                      <TableCell
                        align="left"
                        className="!text-[#e5ebe7]">
                        Start Date
                      </TableCell>
                      <TableCell
                        align="left"
                        className="!text-[#e5ebe7]">
                        Expiry Date
                      </TableCell>
                      <TableCell
                        align="left"
                        className="!text-[#e5ebe7]">
                        Status
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {subscriptions.map((row) => (
                      <TableRow
                        className="!text-[#e5ebe7] !border-0"
                        key={row._id}
                        sx={{ border: 0 }}>
                        <TableCell
                          component="th"
                          className="!text-[#e5ebe7] !capitalize"
                          scope="row">
                          {row.type}
                        </TableCell>
                        <TableCell
                          align="left"
                          className="!text-[#e5ebe7]">
                          {handleDate(row.startDate)}
                        </TableCell>
                        <TableCell
                          align="left"
                          className="!text-[#e5ebe7]">
                          {handleDate(row.expiryDate)}
                        </TableCell>
                        <TableCell
                          align="left"
                          className="!text-[#e5ebe7]">
                          {row.status}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

export default SubscriptionsOrders
