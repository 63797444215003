import React, { useState } from "react"
import { faCancel, faEdit, faUpload } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button } from "@mui/material"
import { toast } from "react-toastify"
import { toastConfig } from "../utils/constants"
import Loader from "./loader"
import { updateAccountInfo, sendResetLink, addReferral } from "../utils/apis"
import { validateName } from "../utils/common-function"
import { CheckIcon, XIcon, RefreshIcon } from "@heroicons/react/outline"
import { PasswordOutlined } from "@mui/icons-material"
import { fetchUserDetails } from "../redux/actions/user"
import { useDispatch, useSelector } from "react-redux"
import ToastError from "../components/toasts/ToastError"
import ToastSuccess from "../components/toasts/ToastSuccess"


const Account = ({ referralCodeUsed }) => {
  const dispatch = useDispatch()
  const reduxUser = useSelector((state) => state.user.userDetails)
  const name = reduxUser?.name;
  const email = reduxUser?.email;
  const [user, setUser] = useState({
    firstName: name?.split(" ")[0],
    lastName: name?.split(" ").slice(1).join(" "),

    email: email,
  })
  const [initialData, setInitialData] = useState({ ...user })
  const [isEditable, setIsEditable] = useState(false)
  const [loading, setLoading] = useState(false) // Initially true
  const [showResetBtn, setShowResetBtn] = useState(false)
  const [resetStatus, setResetStatus] = useState("idle") // Same as above
  const [referralCode, setReferralCode] = useState("")
  const [disableReferralCode, setDisableReferralCode]=  useState(referralCodeUsed)

  const handleResetClick = async () => {
    setResetStatus("loading")
    const response = await sendResetLink({ email })
    let x = response.status === 200 ? "success" : "error"
    setResetStatus(x)
  }

  const renderStatusIcon = (status) => {
    switch (status) {
      case "idle":
        return <PasswordOutlined />
      case "loading":
        return <RefreshIcon className="animate-spin h-5 w-5 text-gray-400" />
      case "success":
        return <CheckIcon className="h-5 w-5 text-green-400" />
      case "error":
        return <XIcon className="h-5 w-5 text-red-400" />
      default:
        return null
    }
  }

  const handleEditClick = () => {
    setIsEditable(!isEditable)
    !isEditable ? setShowResetBtn(true) : setShowResetBtn(false)
  }

  const handleChange = (event) => {
    const { name, value } = event.target
    const response = validateName(value)
    if (response.status) {
      toast(<ToastError message={response.msg} />, toastConfig)
    }
    setUser({ ...user, [name]: value })
  }

  const addReferralCode = async () => {
    if (referralCode.length === 0) {
      toast(<ToastError message={"Please enter a referral code"} />, toastConfig)
      return
    } else {
      try {
        const response = await addReferral(referralCode)
        if (response.status === 200) {
          toast(<ToastSuccess message={"Referral code added successfully"}/>, toastConfig)
          setReferralCode("")
          setUser({...user, referralCodeUsed : true})
          setDisableReferralCode(true)
          dispatch(fetchUserDetails())

        }
        else {
          toast(<ToastError message = {response.message}/>,toastConfig)
        }
      } catch (e) {
        toast(<ToastError message ={"Unable to add you referral code"}/>, toastConfig)
      }
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (JSON.stringify(initialData) === JSON.stringify(user)) {
      toast(<ToastError message={"Modify any field to update"} />, toastConfig)
      setIsEditable(false)
      return
    }
    const userData = { firstName: user.firstName, lastName: user.lastName }
    const response = await updateAccountInfo(userData) // Update data via API
    if (response.status === 200) {
      toast(<ToastSuccess message={"Account details updated successfully"} />, toastConfig)
      dispatch(fetchUserDetails())
    } else {
      toast(<ToastError message={"Failed to update, try again"} />, toastConfig)
    }
    setIsEditable(false)
  }

  return (
    <div className="animate__animated animate__fadeIn">
      <div className="mb-4">
        <div className="flex flex-wrap justify-between items-center sticky top-[3rem] z-20 rounded-[18px] bg-opacity-90">
          <h2 className="font-semibold text-[#e5e7eb] text-xl mb-2 sm:mb-0">Account</h2>

          <div className="flex flex-wrap gap-2 items-center">
            <Button
              style={{ fontFamily: "inherit" }}
              variant="outlined"
              className="
          !font-medium hover:!text-yellow-600 hover:!bg-transparent hover:border-yellow-600 
          !rounded-lg !text-sm !px-2 !py-1.5 !capitalize !text-gray-400 !border-gray-600"
              endIcon={
                isEditable ? (
                  <FontAwesomeIcon className="!text-sm" icon={faCancel} />
                ) : (
                  <FontAwesomeIcon className="!text-sm" icon={faEdit} />
                )
              }
              onClick={handleEditClick}>
              {isEditable ? "Cancel" : "Edit"}
            </Button>

            {isEditable && (
              <Button
                variant="outlined"
                style={{ fontFamily: "inherit" }}
                className="!font-medium hover:!text-yellow-600 hover:!bg-transparent hover:border-yellow-600 
          !rounded-lg !text-sm !px-2 !py-1.5 !capitalize !text-gray-400 !border-gray-600"
                onClick={handleSubmit}>
                Update
                <FontAwesomeIcon icon={faUpload} style={{ height: "14px" }} />
              </Button>
            )}
          </div>
        </div>
      </div>

      {loading ? (
        <Loader />
      ) : (
        <div className="flex flex-wrap gap-4">
          <fieldset className="profile-fieldset min-w-0">
            <legend className="profile-legend">First Name</legend>
            <input
              type="text"
              className="profile-input input mb-3 w-full"
              placeholder={isEditable ? "First Name" : ""}
              name="firstName"
              value={user.firstName || ""}
              disabled={!isEditable}
              onChange={handleChange}
            />
          </fieldset>
          <fieldset className="profile-fieldset min-w-0">
            <legend className="profile-legend">Last Name</legend>
            <input
              type="text"
              className="profile-input input mb-3 w-full"
              placeholder="Last Name"
              name="lastName"
              value={user.lastName || ""}
              disabled={!isEditable}
              onChange={handleChange}
            />
          </fieldset>
          <fieldset className="w-full">
            <legend className="profile-legend">Email ID</legend>
            <input
              type="email"
              className="profile-input input mb-3 w-full"
              placeholder="Email"
              name="email"
              value={user.email || ""}
              disabled={true}
            />
          </fieldset>
        </div>

      )}
      <div className="my-3">
        <div className="flex flex-col justify-start items-start gap-4">
          <div className="w-full">
            <h2 className="font-medium mb-3 text-base mt-3 text-[#e5e7eb]">Reset Password</h2>
            <Button
              variant="outlined"
              className="!font-medium hover:!text-yellow-600 hover:!bg-transparent hover:!border-yellow-600 
                   !rounded-lg !text-sm !px-2 !py-1.5 !capitalize !text-gray-400 !border-gray-600 w-full sm:w-auto"
              onClick={handleResetClick}>
              {resetStatus === "success" ? "Link sent" : resetStatus === "idle" ? "Reset password" : resetStatus === "error" ? "Try again" : "Check email for reset link"}
              {renderStatusIcon(resetStatus)}
            </Button>
          </div>

          <h2 className="font-medium text-base mt-3 text-[#e5e7eb]">Add Referral Code</h2>

          <div className="flex flex-wrap items-center gap-2 w-full">
            <input
              type="text"
              className="profile-input input !px-2 !py-1.5 w-full sm:w-auto max-w-[300px] sm:max-w-none"
              placeholder="Add Referral Code"
              name="referral"
              value={referralCode}
              disabled = {disableReferralCode}
              onChange={(e) => setReferralCode(e.target.value)}
            />
            <Button
              onClick={addReferralCode}
              disabled = {disableReferralCode}
              variant="outlined"
              className="!font-medium hover:!text-yellow-600 hover:!bg-transparent hover:!border-yellow-600 
                   !rounded-lg !text-sm !px-2 !py-1.5 !capitalize !text-gray-400 !border-gray-600 w-full sm:w-auto">
              Add
            </Button>
          </div>
          {disableReferralCode ? <p>You have already used a referral code, contact support for any issues.</p> : <p>Referral code can only be used once, please type carefully. Contact support for other issues.</p>}
        </div>
      </div>

    </div>
  )
}

export default Account
