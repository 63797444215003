import React, { useEffect, useState } from 'react'
import "../../styles.css"
import BoxPageLoader from '../fancy_loader/BoxPageLoader'
import SplitPane from 'split-pane-react/esm/SplitPane'
import { Pane } from 'split-pane-react'


const QuestionPageSkeleton = ({ showToolbar = true }) => {
    const [sizes, setSizes] = useState([400, "40%"])
    const [inlineStyle, setInlineStyle] = useState({
        display: "flex",
        height: showToolbar ? "calc(100vh - 90px)" : "calc(100vh - 100px)",
        marginTop: showToolbar ? "42px" : ".1rem",
        backgroundColor: "rgba(35, 38, 39, 1)",
        transition: "height .5s ease-in-out, margin-top .5s ease-in-out",
    })

    useEffect(() => {
        setInlineStyle((prev) => ({ ...prev, marginTop: showToolbar ? "42px" : ".1rem", height: showToolbar ? "calc(100vh - 90px)" : "calc(100vh - 50px)" }))
    }, [showToolbar])

    return (
        <SplitPane
            split="vertical"
            sizes={sizes}
            onChange={setSizes}
            style={inlineStyle}
        >
            <Pane
                minSize={500}
                maxSize="50%"
                className='h-full'
            >
                <div className="question-data-wrapper flex flex-col justify-center items-center">
                    <BoxPageLoader />
                    <div className="my-10 text-xl font-bold text-center">
                        <span className="animate-pulse bg-gradient-to-r from-gray-200 via-gray-400 to-gray-200 bg-clip-text text-transparent">
                            {/* Please Wait..! We're building up the perfect challenge for you... */}
                        </span>
                    </div>
                </div>
            </Pane>
            <div className="question-data-wrapper flex flex-col justify-center items-center">
                <BoxPageLoader />
                <div className="my-10 text-xl font-bold text-center">
                    <span className="animate-pulse bg-gradient-to-r from-gray-200 via-gray-400 to-gray-200 bg-clip-text text-transparent">
                        Please Wait..! We're building up the perfect challenge for you...
                    </span>
                </div>
            </div>
        </SplitPane>
    )
}

export default QuestionPageSkeleton