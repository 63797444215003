import React, { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useParams } from "react-router-dom"

import CommonHeader from "../assets/common-header"
import Loader from "../assets/loader"
import { fetchCurrentArenaData } from ".//../redux/actions/arena"
import { fetchUserDetails } from "../redux/actions/user"
import { Button, Divider, Drawer, Grid, Link, makeStyles, Tooltip } from "@material-ui/core"
import { useNavigate } from "react-router-dom"

import { fetchQuestion } from "../utils/apis"
import { toast } from "react-toastify"


import Checkout from "./checkout"
import "../assets/css_files/arenas.css"
import LaunchRoundedIcon from "@mui/icons-material/LaunchRounded"
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive"
import LinkedInIcon from "@mui/icons-material/LinkedIn"
import EmailIcon from "@mui/icons-material/Email"
import TwitterIcon from "@mui/icons-material/Twitter"
import { CampaignOutlined, DescriptionOutlined, LeaderboardOutlined, LinkOutlined, MapOutlined } from "@mui/icons-material"
import Scrollspy from "react-scrollspy"
import Leaderboard from "./ui/arena/Leaderboard.jsx"
import RoadMapsTab from "./ui/arena/RoadMapsTab.jsx"
import { arenaAscent, heading, heading2 } from "../utils/tailwind-css.js"
import ToastError from "./toasts/ToastError.js"
import { toastConfig } from "../utils/constants.js"

function Arena() {
  const navigate = useNavigate()
  let { name } = useParams()
  const dispatch = useDispatch()
  const [activeRoadMap, setActiveRoadMap] = useState()
  const [openLeaderBoard, setOpenLeaderBoard] = useState(false)

  const [selectedToolName, setSelectedToolName] = useState()
  const [selectedLevel, setSelectedLevel] = useState()
  const [loading, setLoading] = useState(true)
  const [paymentComponent, setPaymentComponent] = useState(false)

  const { currentArena, userDetails, arenaPrice } = useSelector((state) => ({
    currentArena: state.arena.currentArena,
    userDetails: state.user.userDetails,
    arenaPrice: state.arena.currentArena.price,
  }))

  console.log(currentArena)

  const [dailyPickQuestion, setdailyPickQuestion] = useState(null)

  const toggleLeaderBoard = (newOpen) => () => {
    setOpenLeaderBoard(newOpen)
  }

  useEffect(() => {
    const fetchArena = async () => {
      setLoading(true)
      try {
        await dispatch(fetchCurrentArenaData(name))
        await dispatch(fetchUserDetails())
      } catch (err) {
        console.log(err)
      } finally {
        setLoading(false)
      }
    }

    fetchArena()
  }, [name, dispatch])

  useEffect(() => {
    if (userDetails && userDetails.questionPreferences) {
      setSelectedToolName((prev) => userDetails.questionPreferences.toolName || prev)
      setSelectedLevel((prev) => userDetails.questionPreferences.level || prev)
    } else {
      if (userDetails && !userDetails.questionPreferences) {
        toast(<ToastError message={"Please set your preferences in questions section to get daily pick question"} />, toastConfig)
        navigate("/question")
      }
    }
  }, [userDetails])

  let arenaObject

  const handleJoin = (e) => {
    e.preventDefault()
    // Add logic to join the arena
    arenaObject = {
      ...currentArena,
      price: arenaPrice,
    }
    // Print the combined object
    setPaymentComponent(true)
  }

  const useStyles = makeStyles({
    paper: {
      backgroundColor: "#191919",
      maxWidth: "35vw",
    },
  })

  const classes = useStyles()

  // useEffect(() => {
  //   if (!loading && currentArena?.favPickDailyQuestionIds) {
  //     const fetchAndSetQuestion = async () => {
  //       const randomIndex = Math.floor(Math.random() * currentArena.favPickDailyQuestionIds.length)
  //       const randomQuestionId = currentArena.favPickDailyQuestionIds[randomIndex]
  //       const queryParams = new URLSearchParams({
  //         questionId: randomQuestionId,
  //         toolName: selectedToolName || "",
  //         level: selectedLevel || "",
  //         func: "",
  //       })
  //       const fetchedQuestion = await fetchQuestion(queryParams)
  //       setdailyPickQuestion(fetchedQuestion)
  //     }

  //     fetchAndSetQuestion()
  //   }
  // }, [currentArena, loading])

  if (paymentComponent) {
    return (
      <Checkout
        arena={{
          ...currentArena,
          price: arenaPrice,
        }}
        user={userDetails}
      />
    )
  }

  return (
    <div className="min-h-[calc(100vh-200px)]">
      <CommonHeader bg="#1d1d1d" />
      <div className="bg-[#1d1d1d] mx-auto min-h-[calc(100vh-50px)]">
        {loading ? (
          <div className="flex justify-center items-center">
            <Loader />
          </div>
        ) : (
          <div className="px-8">
            <Drawer
              open={openLeaderBoard}
              onClose={toggleLeaderBoard(false)}
              anchor="right"
              classes={{ paper: classes.paper }}>
              <Leaderboard arenaId={currentArena?._id} />
            </Drawer>


            <div className="grid grid-cols-5 grid-rows-5 gap-24 py-2">

              <div className="col-span-4 row-span-5">

                {/* heading starts */}
                <div className="sticky top-12 bg-[#1d1d1d] z-50 py-2 mb-4">
                  <h2 className="font-bold text-2xl text-center text-[#B4E7FD]">{currentArena?.name}</h2>
                  <div className="gradient" />
                </div>
                {/* heading ends */}

                {/* Objective Starts*/}
                <div className="shiny-border">
                  <div className="myCard p-4 !bg-[#1d1d1d]">
                    <div className="flex items-center">
                      <DescriptionOutlined className={`me-2 text-[${arenaAscent}]`} />
                      <h2 className={`${heading}`}>Objective of {currentArena?.name}</h2>
                    </div>
                    {
                      currentArena?.description !== "" ?
                        <p className={`font-normal text-gray-200 text-left cursor-auto text-sm my-3 leading-6`}>{currentArena && currentArena.description}</p>
                        :
                        <p className={`font-normal text-gray-200 text-left cursor-auto text-sm my-3`}>No Description</p>
                    }
                  </div>
                </div>
                {/* Objective Ends*/}



                {/* Roadmap Starts */}
                <div id="roadmaps" className="py-4 rounded-lg w-[95%]">
                  <div className="flex items-center px-4">
                    <MapOutlined className={`me-2 text-[${arenaAscent}]`} />
                    <h2 className={`${heading}`}>Roadmaps</h2>
                  </div>

                  {
                    currentArena?.roadmaps?.length > 0 ?
                    <RoadMapsTab
                      currentArena={currentArena}
                      handleJoin={handleJoin}
                    />
                    :
                    <p className={`font-normal text-gray-200 text-left cursor-auto text-sm my-3 mx-4`}>No Roadmaps</p>
                  }
                </div>
                {/* Roadmap Ends */}

              </div>

              <div className="row-span-5 col-start-5 py-2">

                {/* sticky section starts  */}
                <div className="sticky top-14">
                  {/* links starts */}
                  <div
                    id="links"
                    className="flex gap-3 items-center mb-4 flex-wrap justify-end">
                    <button
                      className="relative inline-flex active:scale-95 transistion overflow-hidden rounded-lg p-[1px] text-xs focus:outline-none"
                      onClick={toggleLeaderBoard(true)}
                    >
                      <span className="absolute inset-[-1000%] animate-[spin_2s_linear_infinite] bg-[conic-gradient(from_90deg_at_50%_50%,#e7029a_0%,#f472b6_50%,#bd5fff_100%)]"></span>
                      <span className="inline-flex h-full w-full cursor-pointer items-center justify-center rounded-lg bg-black bg-opacity-80 text-xs font-medium text-white backdrop-blur-3xl gap-2 px-4 py-1.5">
                        <LeaderboardOutlined className="!text-base" />
                        Leaderboard
                      </span>
                    </button>
                  </div>

                  {/* links ends */}

                  {/* noticeboard starts */}

                  <div className="shiny-border mb-4">
                    <div className="myCard !bg-[#1d1d1d]">

                      {/* heading starts*/}
                      <div className="relative">
                        <div className="sticky top-0 z-0">
                          <h1 className="text-xl font-semibold text-center text-gray-900 rounded-xl py-2">
                            <span className="block">
                              <span className={`${heading}`}>Announcements</span>
                            </span>
                          </h1>
                        </div>
                      </div>
                      {/* heading ends */}

                      {/* Announcements rendering starts*/}
                      <div
                        className="h-full overflow-auto px-3"
                        style={{ paddingBottom: "8rem" }}>
                        {currentArena?.noticeBoard?.length === 0 && <h3 className="text-gray-400 text-sm">No new updates</h3>}
                        {currentArena?.noticeBoard && currentArena?.noticeBoard?.map((notice) => <p className="mb-2 text-gray-400 text-sm">{notice}</p>)}
                      </div>
                      {/* Announcements rendering ends */}


                    </div>
                  </div>

                  <div className="shiny-border h-full mb-4">
                    <div className="myCard !bg-[#1d1d1d]">

                      {/* heading starts*/}
                      <div className="relative">
                        <div className="sticky top-0 z-0">
                          <h1 className="text-xl font-semibold text-center text-gray-900 rounded-xl py-2">
                            <span className="block">
                              <span className={`${heading}`}>Reference Links</span>
                            </span>
                          </h1>
                        </div>
                      </div>
                      {/* heading ends */}

                      {/* Announcements rendering starts*/}
                      <div
                        className="h-full overflow-auto px-3"
                        style={{ paddingBottom: "8rem" }}>
                        {currentArena?.referenceLinks?.length === 0 && <h3 className="text-gray-400 text-sm">No Reference Links Available</h3>}
                        {currentArena?.referenceLinks &&
                          currentArena?.referenceLinks?.map((referenceLink, i) => (
                            <Tooltip
                              title={referenceLink?.description}
                              arrow>
                              <Link
                                className="!mb-2 !text-gray-400 !text-sm block truncate"
                                underline="none"
                                color="inherit"
                                href={referenceLink?.url}>
                                <LinkOutlined className="!text-gray-300" /> {referenceLink?.description}
                              </Link>
                            </Tooltip>
                          ))}
                      </div>
                      {/* Announcements rendering ends */}


                    </div>
                  </div>




                  {/* noticeboard ends  */}
                </div>
                {/* sticky section ends  */}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default Arena



