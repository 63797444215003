import React from 'react';
import { Link } from 'react-router-dom';
import { ArenaImage5 } from '../../assets/image_files/index';

const ArenaContent = () => {
  return (
      <>
        <div className="container flex flex-col md:flex-row justify-between items-center mx-auto pt-16">
          <div className='arena-description md:w-1/2 px-3'>
            <h1 className="text-4xl capitalize font-bold text-center sm:text-center md:text-left mb-3"
              style={{
                backgroundImage: "linear-gradient(96deg, #dddcff, #c1bfff)",
                WebkitTextFillColor: "transparent",
                WebkitBackgroundClip: "text"
              }}
              fontFamily="inherit"
            >ARENA
            </h1>
            <p className="text-white font-light p-0 text-slate-300"
            >
              Arena provides visibility on growth and efforts of peers across a group having similar interests and coach.
              <br /><br />
              We deeply believe in utilising AI to improve human life, classroom is an attempt to
              join best of both the worlds and enhance learning outcomes for both mentors and students
            </p>
          </div>
          <div className="arena-content-illustration w-full md:w-1/2">
              <img src={ArenaImage5} alt="Arena Illustration" className="
              h-[250px] w-[250px]
              sm:h-[250px] sm:w-[250px]
              md:h-[350px] md:w-[350px]
              mx-auto my-6 sm:my-5"
              loading='lazy'
              />
          </div>
        </div>
        <div className="container mx-auto flex flex-col justify-center items-center flex-wrap">
          <h1 className="text-white mb-3 p-2 text-center">Join classroom with your favourite mentors</h1>
          <button className="cta-btn rounded-full mx-auto px-4 py-2 mb-3"
            style={{ fontFamily: "inherit" }}
          >
            <Link to='/signup'>Join Now</Link>
          </button>

          <div className='flex justify-center items-center gap-3 flex-wrap mb-4'>
          <p className="text-white text-xl md:text-2xl font-medium text-center">
            Want to create your own classroom
          </p>

            <Link to="/arenaform">
              <div className="group relative mx-auto flex max-w-fit flex-row items-center justify-center rounded-2xl bg-white/40 px-4 py-1.5 text-sm font-medium shadow-[inset_0_-8px_10px_#8fdfff1f] backdrop-blur-sm transition-shadow duration-500 ease-out [--bg-size:300%] hover:shadow-[inset_0_-5px_10px_#8fdfff3f] dark:bg-black/40">
                <div className="absolute inset-0 block h-full w-full animate-gradient bg-gradient-to-r from-[#ffaa40]/50 via-[#9c40ff]/50 to-[#ffaa40]/50 bg-[length:var(--bg-size)_100%] p-[1px] ![mask-composite:subtract] [border-radius:inherit] [mask:linear-gradient(#fff_0_0)_content-box,linear-gradient(#fff_0_0)]"></div>
                <div className="absolute inset-0 block h-full w-full animate-gradient bg-gradient-to-r from-[#ffaa40]/50 via-[#9c40ff]/50 to-[#ffaa40]/50 bg-[length:var(--bg-size)_100%] [border-radius:inherit] [mask:linear-gradient(#fff_0_0)_content-box,linear-gradient(#fff_0_0)] p-[1px] ![mask-composite:subtract]">
                </div>🎉
                <div data-orientation="vertical" role="none" className="shrink-0 bg-border w-[1px] mx-2 h-4"></div>
                <span className="animate-gradient bg-gradient-to-r from-[#ffaa40] via-[#9c40ff] to-[#ffaa40] bg-[length:var(--bg-size)_100%] bg-clip-text text-transparent inline font-bold">Talk to Us</span>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="ml-1 h-4 w-4 text-gray-500"><path d="m9 18 6-6-6-6"></path></svg>
              </div>
            </Link>
          </div>



        </div>
      </>
  )
}

export default ArenaContent;