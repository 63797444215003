import React, { useEffect, useState } from "react"
import Loader from "../../assets/loader"
import CalendarHeatmap from 'react-calendar-heatmap';
import 'react-calendar-heatmap/dist/styles.css';
import ReactTooltip from "react-tooltip";


const HeatMapChart = ({ data }) => {
  const [chartData, setChartData] = useState()
  const [totalQuestionsSolved, setTotalQuestionsSolved] = useState(0)

  const getLevel = (questionsSolved) => {
    const level = Math.min(Math.floor((questionsSolved - 1) / 10) + 1, 4)
    return level
  }

  function getStartAndEndDate(data) {
    if (data?.length === 0) return { startDate: null, endDate: null }; // If data is empty
  
    // Extract the dates from the data and sort them in ascending order
    const dates = data?.map(item => new Date(item.date));
    dates?.sort((a, b) => a - b); // Sort in ascending order
  
    // The start date will be the first date in the sorted array
    const startDate = dates[0];
    startDate.setDate(1);
  
    // Create the end date by adding 11 months to the start date
    const endDate = new Date(startDate);
    endDate.setMonth(startDate.getMonth() + 8); // Add 11 months to the start date
  
    // Return both dates in 'YYYY-MM-DD' format
    const formatDate = (date) => date?.toISOString().split('T')[0]; // Convert to YYYY-MM-DD format
  
    return {
      startDate: formatDate(startDate),
      endDate: formatDate(endDate)
    };
  }

  useEffect(() => {
    let tempTotalQuestionsSolved = 0;
    const result = data.reduce((acc, entry) => {
      const { date, questionsSolved } = entry
      tempTotalQuestionsSolved += questionsSolved;

      if (questionsSolved > 0) {
        acc.push({
          "date": date,
          count: getLevel(questionsSolved)
        })
      }

      
      return acc
    }, [])
    setChartData({
      contributions: result,
      dates: getStartAndEndDate(result)
    })
    setTotalQuestionsSolved(tempTotalQuestionsSolved)
  }, [data])

  useEffect(()=>{
    console.log(data, "HEATMAP DATA")
  }, [chartData])



  if (!chartData) return <Loader />


  return chartData?.contributions?.length !== 0 ? (
    <div className="px-4 h-[19rem] flex justify-center flex-col">
      <span className="text-sm mb-6 block">{totalQuestionsSolved} questions solved during {chartData.dates.startDate} - {chartData.dates.endDate}</span>

      <CalendarHeatmap
        startDate={chartData.dates.startDate}
        endDate={chartData.dates.endDate}
        values={chartData.contributions}
        showWeekdayLabels={true}
        gutterSize={2}
        weekdays={['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']}
        showOutOfRangeDays={false}
        tooltipDataAttrs={(value) => {
          if (value?.date) {
            const submissionText = value.count === 1 ? 'submission' : 'submissions';
            
            return {
              "data-tip": `${value?.date}`,
            };
          }
          return {};
        }}

        classForValue={(value) => {
          if (!value) {
            return 'color-empty';
          }
          return `color-scale-${value.count}`;
        }}
      />
      <ReactTooltip/>
      
    </div>
  ) : (
    <div>
      <h2 className="text-xl text-center mt-auto h-[310px] flex justify-center items-center">Solve Questions to See Submissions</h2>
    </div>
  )
}

export default HeatMapChart
