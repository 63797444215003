import { fetchArenaByName, fetchEnrolledArenasList, fetchAvailableArenasList, getRoadmapQuestions } from '../../utils/apis';

export const setAvailableArenasData = (availableArenasList) => {
    return {
        type: 'SET_AVAILABLE_ARENAS',
        payload: availableArenasList
    }
}

export const setEnrolledArenasData = (enrolledArenasList) => {
    return {
        type: 'SET_ENROLLED_ARENAS',
        payload: enrolledArenasList
    }
}

export const setCurrentArenaData = (currentArenaData) => {
    return {
        type: 'SET_CURRENT_ARENA',
        payload: currentArenaData
    }
}
export const setRoadmapQuestions = (currentRoadmapQues) => {
    return {
            type : 'SET_ROADMAP_QUESTIONS',
            payload : currentRoadmapQues
    }
}

export const fetchArenasList = () => {
    return async dispatch => {
        let availableArenasRes = await fetchAvailableArenasList();
        if (availableArenasRes.status === 404) {
            dispatch(setAvailableArenasData([])); // Dispatch empty array if status is 404
        } else {
            dispatch(setAvailableArenasData(availableArenasRes.data));
        }

        let enrolledArenasRes = await fetchEnrolledArenasList();
        if (enrolledArenasRes.status === 404) {
            dispatch(setEnrolledArenasData([])); // Dispatch empty array if status is 404
        } else {
            dispatch(setEnrolledArenasData(enrolledArenasRes.data));
        }

    }
}

export const fetchCurrentArenaData = (nameOfArena) => {
    return async dispatch => {
        let res = await fetchArenaByName(nameOfArena);
        dispatch(setCurrentArenaData(res.data));
    }
}

export const fetchCurrentRoadmapQuestions = (arenaName, roadmapName,roadmapDesc) => {
    return async dispatch => {
        let res = await getRoadmapQuestions(arenaName, roadmapName, roadmapDesc)
        dispatch(setRoadmapQuestions(res?.data?.questionStatements))
        console.log(res)
        return res
    }

}