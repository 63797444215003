import { React, useState } from 'react';
import { useLinkedIn } from "react-linkedin-login-oauth2";
import { toast } from "react-toastify";
import { sendLinkedinCodeToBackend } from '../utils/apis';
import { Avatar, Box, Button } from '@mui/material';
import { LinkedIn } from '@mui/icons-material';
import ToastError from '../components/toasts/ToastError';
import ToastSuccess from '../components/toasts/ToastSuccess';

const LinkedInLoginButton = () => {
  const [code, setCode] = useState("");
  const [accessToken, setAccessToken] = useState("");
  const { linkedInLogin } = useLinkedIn({
    clientId: process.env.REACT_APP_LINKEDIN_CLIENT_ID,
    redirectUri: origin + `/linkedin-openid/callback`,
    onSuccess:
      async (code) => {
        try {
          setCode(code);
          const response = await sendLinkedinCodeToBackend(code);
          if (response.status === 200) {
            // console.log(response.data);
            toast(<ToastSuccess message={'You are now logged in'}/>); // Display success toast
            localStorage.setItem('usertoken', response.data.Token)
        localStorage.setItem('refreshToken', response.data.refreshToken)
            window.location.reload()
          } else {
            toast(<ToastError message={'Error while signing you up'}/>); // Display error toast
          }

        } catch (error) {
          console.error('Error exchanging authorization code for access token:', error);
          setCode(null);
        }

      },
    scope: "email profile openid",
    onError: (error) => {
      console.log(error);
      toast(<ToastError message={'Error while signing you up'}/>); // Display error toast
      setCode("");
    },
  });

  return (
    <>
      {/* <Button
        onClick={linkedInLogin}
        variant='text'
        size='small'
        startIcon={<Avatar src={'https://cdn1.iconfinder.com/data/icons/logotypes/32/circle-linkedin-512.png'}
        sx={{ width: 40, height: 40 }}
        />}
      >
      </Button> */}

      <Box
        onClick={linkedInLogin}
        className="flex justify-center items-center"
      >
        {/* <Avatar src={'https://cdn1.iconfinder.com/data/icons/logotypes/32/circle-linkedin-512.png'}
        sx={{ width: 40, height: 40, cursor:"pointer"}}
        /> */}

        {/* <button className='bg-transparent border-purple-500 border-solid border rounded-full p-3 hover:bg-[#0077B5] transition-all text-purple-500 hover:text-white hover:border-[#0077B5]'>
          <i className="fab fa-linkedin"></i>
        </button> */}

        <button className='bg-transparent border-slate-700 border-solid border rounded-full p-3 hover:bg-[#0077B5] transition-all text-slate-500 hover:text-white hover:border-[#0077B5]'>
          <i className="fab fa-linkedin"></i>
        </button>
      </Box>
    </>
  );
};

export default LinkedInLoginButton;
