import React, { useState } from "react"
import { Badge, Button, Tooltip } from "@mui/material"
import LockOpenIcon from "@mui/icons-material/LockOpen"
import { useNavigate } from "react-router-dom"

const PremiumButton = ({ questionsSeen, maxFreeQuestions = 50 }) => {
  const navigate = useNavigate()
  const [isHovered, setIsHovered] = useState(false) // State to track hover
  const percentageUsed = (questionsSeen / maxFreeQuestions) * 100
  const questionsLeft = maxFreeQuestions - questionsSeen

  const handleSubscriptionClick = () => {
    navigate("/pricing") // Redirect to subscription page
  }

  // Mouse hover handlers
  const handleMouseEnter = () => setIsHovered(true)
  const handleMouseLeave = () => setIsHovered(false)

  return (
    <Tooltip
      title={``}
      arrow>
      <Button
        variant="outlined"
        className="!text-sm !text-gray-400 !capitalize !px-2 !py-1.5 hover:!text-gray-200 !border-transparent hover:!border-[#45c2709a] hover:!border !transition-all"
        onClick={handleSubscriptionClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        startIcon={<LockOpenIcon className="!text-base" />}
        style={{
          fontFamily: "inherit",
          // border: isHovered ? "0.5px solid #45c2709a" : "none",
          cursor: "pointer",
          background: isHovered
            ? `linear-gradient(to right, #45c2709a, transparent ${percentageUsed}%)` // Fading effect on hover
            : "transparent", // Normal background when not hovered
        }}>
        Premium
        <span className="!text-xs !text-gray-300 !bg-[#343839] !rounded !capitalize px-2 py-0.5">{50 - questionsLeft}/50</span>
      </Button>
    </Tooltip>
  )
}

export default PremiumButton
