import { Avatar, Tooltip } from '@mui/material'
import { makeStyles } from "@material-ui/core/styles"

import React, { useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import RewardCoinWithoutAnimation from '../../RewardPoint/RewardCoinWithoutAnimation'
import { useSelector } from 'react-redux'
import Badges from '../../../assets/badges'
import { titleBarLogo } from '../../../assets/image_files'
import { logout } from '../../../utils/common-function'
import CloseIcon from '@mui/icons-material/Close';
import Filter from '../../../assets/filter'
const MobileMenu = ({ questionMeta, primaryTool, fromURL, topics, selectedTopic, onSubmit, modifyFilterList, searchFilterList, onClose }) => {
    const userMetrics = useSelector((state) => state.userMetrics.userMetrics)
    const reduxUser = useSelector((state) => state.user.userDetails)

    return (
        <div
            className='bg-black bg-opacity-90 h-screen w-screen z-[1000] fixed left-0 top-0'
        >

            <nav className="flex flex-col justify-center items-center gap-4 text-white h-full px-6">
                <div className='self-end' onClick={() => onClose(false)}>
                    <CloseIcon />
                </div>
                <div>
                    {reduxUser ? (
                        <Avatar

                            className=" !bg-[#343839] !capitalize !text-lg !font-semibold !cursor-pointer">
                            {" "}
                            {reduxUser.name.split(" ")[0][0]}
                        </Avatar>
                    ) : (
                        <Avatar

                            className=" !bg-[#343839] !capitalize !text-lg !font-semibold !cursor-pointer"
                            src={titleBarLogo}>
                            {" "}
                        </Avatar>
                    )}
                </div>

                {/* Filter */}
                <div className='flex sm:flex md:hidden'>
                <Filter
                    fromURL={fromURL}
                    topics={searchFilterList.functions}
                    selectedTopic={selectedTopic}
                    onSubmit={onsubmit}
                    modifyFilterList={modifyFilterList}
                />
                </div>
                {/* badges */}
                <div className="bg-[#343839] text-[#D0AA45] text-xs font-medium px-2.5 py-0.5 rounded flex gap-3 justify-between items-center cursor-default">
                    <Tooltip
                        placement="bottom-start"
                        arrow
                        title="Reward Points">
                        {userMetrics ? (
                            <p className="inline-flex text-sm gap-1 justify-center items-center">
                                {" "}
                                <RewardCoinWithoutAnimation />
                                {userMetrics.totalRewardPoints}
                            </p>
                        ) : (
                            ""
                        )}
                    </Tooltip>

                    <Tooltip
                        placement="bottom-start"
                        arrow
                        title="Current Level">
                        <p className="text-sm"> {questionMeta && `Level ${questionMeta?.Level[primaryTool]}`}</p>
                    </Tooltip>

                    {userMetrics?.badges?.length !== 0 && <Badges badges={userMetrics?.badges} />}
                </div>
                <Link to="/dashboard" className='hover:!text-gray-500 transition-all'>Dashboard</Link>
                <Link to="/arenas" className='hover:!text-gray-500 transition-all'>Arenas</Link>
                <Link to="/profile/notifications" className='hover:!text-gray-500 transition-all'>Inbox </Link>
                <Link to="/profile/basic-info" className='hover:!text-gray-500 transition-all'>Profile</Link>
                <Link to="/faqs" className='hover:!text-gray-500 transition-all'>FAQs</Link>
                <button
                    className="bg-red-500 px-3 py-2 hover:bg-red-600 m-0 p-0 transition-all"
                    onClick={logout}>
                    Logout
                </button>{" "}
            </nav>
        </div>
    )
}

export default MobileMenu