import React from 'react'
import { AccountCircleRounded, CircleNotificationsRounded, LiveHelp, Logout } from "@mui/icons-material"
import { Link } from 'react-router-dom'
import { logout } from '../../utils/common-function'
import LineDivider from '../LineDivider'

const styles = {
    headerDropdownContainer: {
        position: "absolute",
        zIndex: "10000",
        top: "3.1rem",
        right: "1.25rem",
        //backgroundColor: "#232627",
        //border: "1px solid #FEFEFE1A",
        borderRadius: "18px",
        //padding: "1rem",
        boxShadow: "0 2px 4px rgba(0,0,0,0.2)",
        overflowY: "auto",
        color: "#fefefe",
        marginTop: "1px",
        fontSize: "14px",
    },
}


const navOptions = [
    { name: "Inbox", navigateTo: "/profile/notifications", icon: <CircleNotificationsRounded className="!text-base" /> },
    { name: "Profile", navigateTo: "/profile/", icon: <AccountCircleRounded className="!text-base" /> },
    { name: "FAQs", navigateTo: "/faqs", icon: <LiveHelp className="!text-base" /> },

];


const ProfileDropdown = () => {
    return (
        <div
            style={styles.headerDropdownContainer}
            className="bg-[#1d1d1d] border border-gray-600">

            <ul className=" space-y-2 p-6">
                {
                    navOptions?.map((opt, index) =>
                    (
                        <li className="flex gap-2 items-center text-gray-400 hover:text-white transition-all">
                            {opt.icon}
                            <Link
                                to={`${opt.navigateTo}`}
                            >
                                {opt.name}
                            </Link>
                        </li>
                    )
                    )
                }

                <LineDivider/>
                <li>
                    <button
                        className="text-[#989898] hover:text-red-500 m-0 p-0"
                        onClick={logout}>
                        <Logout className="!text-base" />
                        Logout
                    </button>{" "}
                    {/* Use a button for logout */}
                </li>
            </ul>
        </div>
    )
}

export default ProfileDropdown