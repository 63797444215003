import React from 'react'
import { Link } from 'react-router-dom';

const UpgradeToPremium = () => {
    return (
        <div className="max-w-lg p-2 sm:p-2 md:p-4">
            <div className="group/card m-auto text-gray-300 hover:brightness-90 transition-all cursor-pointer bg-gradient-to-tl from-gray-900 to-gray-950 hover:from-gray-800 hover:to-gray-950 border-r-2 border-t-2 border-gray-900 rounded-lg overflow-hidden relative">
                <div className="px-8 py-10 flex flex-col justify-center items-center">
                    <div className="font-bold text-base text-center mb-4">
                        Unlock your full potential upgrade to <span className="text-yellow-400">premium!</span>
                    </div>

                    <Link
                        className="group/button relative inline-block p-px font-semibold leading-6 text-white bg-gray-800 shadow-2xl cursor-pointer rounded-xl shadow-zinc-900 transition-transform duration-300 ease-in-out hover:scale-105 active:scale-95"
                        to="/pricing"
                    >
                        <span
                            className="absolute inset-0 rounded-xl bg-gradient-to-r from-teal-400 via-blue-500 to-purple-500 p-[2px] opacity-0 transition-opacity duration-500 group-hover/button:opacity-100"
                        ></span>

                        <span className="relative z-10 block px-4 py-2 rounded-xl bg-gray-950">
                            <div className="relative z-10 flex items-center space-x-2 text-base">
                                Pricing
                            </div>
                        </span>
                    </Link>
                </div>

                <div className="h-2 w-full bg-gradient-to-l via-yellow-500 group-hover/card:blur-xl blur-2xl m-auto rounded transition-all absolute bottom-0"></div>
                <div className="h-0.5 group-hover/card:w-full bg-gradient-to-l via-yellow-950 group-hover/card:via-yellow-500 w-[70%] m-auto rounded transition-all"></div>
            </div>
        </div>

    )
}

export default UpgradeToPremium