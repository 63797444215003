import Styles from "./Card.module.css";
import React, { useState } from "react";
import { useSpring, animated } from "react-spring";

function CarouselCard() {
    const [show, setShown] = useState(false);

    const props3 = useSpring({
        transform: show ? "scale(1.03)" : "scale(1)",
        boxShadow: show
            ? "0 20px 25px rgb(0 0 0 / 15%)"
            : "0 2px 10px rgb(0 0 0 / 8%)",
            
    });
    
    return (
        <animated.div
            className={Styles.card}
            style={props3}
            onMouseEnter={() => setShown(true)}
            onMouseLeave={() => setShown(false)}
        >
            <div className='shiny-border insightCard'>
                <div className="notiglow"></div>
                <div className="notiborderglow"></div>
                <div className={`myCard h-full`}>
                    <div className="content">
                        <div className="title !m-0">Title</div>
                        <div className="description !text-center !leading-6 text-slate-300">
                            Lorem, ipsum dolor sit amet consectetur adipisicing elit. Recusandae, porro illo nulla eligendi, quia quisquam pariatur quibusdam eius odit commodi, ipsa exercitationem tempore incidunt quidem! Libero blanditiis maiores aliquam! Voluptas eum exercitationem perferendis a suscipit?
                        </div>
                    </div>
                </div>
            </div>
        </animated.div>
    );
}

export default CarouselCard;
