export const levelwiseTopics = {
    Python: {
        1: [
            "Select Columns",
            "boolean indexing",
            "sort_values",
            "query",
            "loc",
            "iloc",
            "isin",
            "between",
            "filter",
            "where",
            "mask",
            "dropna",
            "duplicated",
            "drop_duplicates",
            "notna",
            "clip",
            "notnull"
        ],
        2: [
            "sum",
            "mean",
            "median",
            "min",
            "max",
            "agg",
            "count",
            "size",
            "std",
            "var",
            "sem",
            "nunique",
            "str.cat",
            "str.split",
            "str.rsplit",
            "str.join",
            "str.contains",
            "str.replace",
            "str.strip",
            "str.lower",
            "str.upper",
            "str.capitalize",
            "str.startswith",
            "str.endswith",
            "str.len",
            "to_datetime",
            "dt.date",
            "dt.year",
            "dt.month",
            "dt.dayofweek",
            "dt.dayofyear",
            "dt.daysinmonth",
            "dt.is_month_start",
            "dt.is_month_end",
            "dt.is_quarter_start",
            "dt.is_quarter_end",
            "dt.quarter",
            "dt.strftime"
        ],
        3: [
            "transform",
            "cumrank",
            "cumcount",
            "cumsum",
            "shift",
            "rank"
        ],
        4: [
            "groupby",
            "ngroup"
        ],
        5: [
            "np.select",
            "np.where",
            "cut",
            "lambda",
            "map",
            "replace",
            "apply",
            "concat",
            "merge",
            "pivot"
        ],
        6: [
            "pivot_table",
            "merge_asof",
            "merge_ordered",
            "crosstab",
            "applymap",
            "explode",
            "stack",
            "unstack",
            "melt",
            "get_dummies"
        ],
        7: [
            "groupby agg",
            "groupby apply",
            "groupby transform",
            "window functions",
            "rolling",
            "expanding",
            "resample"
        ],
        8: [
            "custom aggregation functions",
            "parallel processing with Dask",
            "optimize pandas code",
            "memory management",
            "performance profiling"
        ],
        9: [
            "advanced datetime manipulation",
            "time series forecasting",
            "pandas extensions (e.g., categorical types)",
            "custom plotting with pandas"
        ],
        10: [
            "building custom pandas functions",
            "advanced multi-indexing",
            "handling large datasets with pandas",
            "distributed computing with pandas"
        ]
    },

    SQL: {
        1: [
            "SELECT",
            "SELECT *",
            "DISTINCT",
            "SELECT DISTINCT",
            "WHERE",
            "ORDER BY"
        ],
        2: [
            "SUM",
            "AVG",
            "MIN",
            "MAX",
            "COUNT",
            "COUNT DISTINCT",
            "COUNT *",
            "STDDEV",
            "VARIANCE",
            "VAR",
            "EXTRACT",
            "DATE_TRUNC",
            "TRIM",
            "UPPER",
            "LOWER",
            "INTERVAL",
            "CONCATENATE",
            "SUBSTRING",
            "REPLACE",
            "LENGTH",
            "ROUND",
            "POSITION",
            "INITCAP",
            "CURRENT_DATE",
            "COALESCE"
        ],
        3: [
            "ROW_NUMBER",
            "RANK",
            "DENSE_RANK",
            "NTILE",
            "LEAD",
            "LAG",
            "WINDOW FUNCTION"
        ],
        4: [
            'GROUP BY'
        ],
        5: [
            "CASE WHEN",
            "LEFT JOIN",
            "RIGHT JOIN",
            "INNER JOIN",
            "UNION",
            "UNION ALL",
            "SELF JOIN",
            "HAVING",
            "FULL OUTER JOIN"
        ],
        6: [
            "JOIN ON with multiple conditions",
            "Subqueries",
            "Correlated subqueries",
            "Common Table Expressions (CTEs)",
            "Recursive queries"
        ],
        7: [
            "Aggregation with GROUP BY",
            "HAVING vs WHERE",
            "Window Functions in depth",
            "Rank and Row Number calculations"
        ],
        8: [
            "Advanced JOINs (Cross Join, Self Join)",
            "Pivoting Data with SQL",
            "Nested SELECTs",
            "SQL optimization techniques"
        ],
        9: [
            "Stored Procedures",
            "Triggers",
            "Indexing and performance",
            "Transactions and ACID properties"
        ],
        10: [
            "Advanced SQL tuning",
            "Partitioning",
            "Sharding",
            "Advanced analytics in SQL"
        ]
    },

    excel: {
        1: [
            "Basic Formulas",
            "SUM",
            "AVERAGE",
            "MIN",
            "MAX",
            "IF",
            "COUNTIF",
            "VLOOKUP",
            "HLOOKUP"
        ],
        2: [
            "Conditional Formatting",
            "COUNTIFS",
            "SUMIFS",
            "INDEX",
            "MATCH",
            "LEFT",
            "RIGHT",
            "MID",
            "TEXT"
        ],
        3: [
            "Pivot Tables",
            "Advanced Formulas",
            "Array Formulas",
            "Dynamic Arrays",
            "IFERROR",
            "CHOOSE",
            "INDIRECT"
        ],
        4: [
            "Data Validation",
            "Advanced Charts",
            "Power Query",
            "Power Pivot",
            "What-If Analysis",
            "Scenario Manager"
        ],
        5: [
            "Macros & VBA",
            "Automating Reports",
            "Advanced Pivot Tables",
            "Data Modeling",
            "Data Analysis Expressions (DAX)"
        ],
        6: [
            "Power BI Integration",
            "Complex Dashboard Creation",
            "Data Slicing and Dicing",
            "Real-time Data Updates"
        ],
        7: [
            "Advanced Excel Dashboards",
            "Custom Excel Add-ins",
            "Excel Automation for Business"
        ],
        8: [
            "Integration with Python for Data Science",
            "Connecting Excel to external databases",
            "Handling large datasets"
        ],
        9: [
            "Big Data in Excel",
            "Advanced Financial Modeling in Excel"
        ],
        10: [
            "Excel for Machine Learning",
            "Advanced Automation with VBA"
        ]
    }
}
