import React from 'react'

const LockingBar = ({levelCompletedPercentage, lockPosition, showLock, width="full"}) => {
  return (
    <div className={`relative flex h-2 w-${width} items-center`}>
    {/* Progress Bar */}
    <span
      className="h-full w-full rounded-lg"
      style={{
        background: `linear-gradient(to right, #31C48D 0%, #046C4E ${levelCompletedPercentage}%,  #4B5563 ${levelCompletedPercentage + 5}%, #4B5563 100%)`, // Gradient fill
      }}
    ></span>
    {/* Lock Icon */}

    {/* {
      showLock &&
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="1em"
      height="1em"
      fill="currentColor"
      className="absolute -translate-x-1/2 text-yellow-400 text-opacity-50 -mt-1.5 h-5 w-5"
      style={{
        left: `${lockPosition}%`,
      }}
    >
      <path
        fill-rule="evenodd"
        d="M7 8v2H6a3 3 0 00-3 3v6a3 3 0 003 3h12a3 3 0 003-3v-6a3 3 0 00-3-3h-1V8A5 5 0 007 8zm8 0v2H9V8a3 3 0 116 0zm-3 6a2 2 0 100 4 2 2 0 000-4z"
        clip-rule="evenodd"
      ></path>
    </svg>

    } */}

  </div>
  )
}

export default LockingBar