import React, { useState, useEffect } from "react"
import { getNotifications, handleSeeNotification, unseenNotificationsCount } from "../utils/apis"
import { Button, Link as MaterialLink, MenuItem } from "@mui/material"
import Loader from "./loader"
import { Link } from "react-router-dom"
import { emptyInbox } from "../assets/image_files"
import Dropdown from "../components/ui/Dropdown"
import { FilterAltOutlined } from "@mui/icons-material"

const Notifications = () => {
  const [notifications, setNotifications] = useState(null)
  const [notificationFilter, setNotificationFilter] = useState("All")
  const [unseeenCount, setUnSeenCount] = useState()
  const [filteredNotifications, setFilteredNotifications] = useState(notifications)

  const fetchUnseenCount = async () => {
    const response = await unseenNotificationsCount()
    if (response.status === 200) {
      setUnSeenCount(response?.unseenCount)
    }
    else {
      // setInboxCount(null) implement it later
    }
  }

  useEffect(() => {
    fetchUnseenCount()
  }, [])

  useEffect(() => {
    const fetchUnseenNotifications = async () => {
      const response = await getNotifications()

      if (response.status === 200) {
        setNotifications(response?.data)
        setFilteredNotifications(response?.data)
      } else {
        setNotifications([])
      }
    }
    fetchUnseenNotifications()
  }, [])


  const handleUpdate = async (notificationId) => {
    const response = await handleSeeNotification(notificationId)
    if (response.status === 200) {
      fetchUnseenCount()
      let updatedStatus = notifications
      updatedStatus = updatedStatus.map((notification) => {
        if (notification._id === notificationId) {
          notification.seen = true
        }
        return notification
      })
      setNotifications(updatedStatus)
      handleFilterChange(notificationFilter)
    }
  }

  const handleFilterChange = (filter) => {
    setNotificationFilter(filter)
    if(filter === "All"){
      setFilteredNotifications(notifications)
      return
    }
    const boolean = filter === "Seen" ? true : false
    const filteredArr = notifications?.filter((noti)=> noti?.seen === boolean)
    setFilteredNotifications(filteredArr)
  }


  const handleDate = (dateString) => {
    const dateObject = new Date(dateString)

    const localDateTime = dateObject.toLocaleString("en-US", {
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
    })

    return localDateTime
  }

  const groupByMonth = (notifications) => {
    return notifications.reduce((acc, notification) => {
      const date = new Date(notification.createdAt);
      const month = date.toLocaleString("en-US", { month: "long" }); // Full month name
      const year = date.getFullYear();
      const key = `${month}, ${year}`; // Format: Month, Year

      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(notification);

      return acc;
    }, {});
  };


  return !notifications ? (
    <Loader />
  ) : (
    <div className="animate__animated animate__fadeIn">
      <div className="mb-4 flex gap-6">
        <h2 className="font-semibold text-[#e5e7eb] text-xl">Inbox {unseeenCount > 0 && <span className="text-gray-400 font-normal">({unseeenCount})</span>}</h2>

        <Dropdown
          title={notificationFilter}
          icon={<FilterAltOutlined />}
          children={["All", "Seen", "Unseen"].map((filter) => (
            <MenuItem
              key={filter}
              onClick={() => handleFilterChange(filter)}
              className="!text-gray-200 !font-light !text-xs">
              {filter}
            </MenuItem>
          ))}
        />
      </div>

      {filteredNotifications?.length === 0 ? (
        <div className="flex flex-col justify-center items-center h-full">
          <img
            src={emptyInbox}
            alt="No Data Found"
            className="w-[250px] mb-3 opacity-35"
          />
          <h2 className="text-base text-gray-400">Nothing is here!</h2>
        </div>
      ) : (
        <div className="myCard !bg-transparent">
          {filteredNotifications?.map( notification => (
            // <div key={monthYear} className="max-w-md">
            //   <div class="inline-flex items-center justify-center w-full relative">
            //     <hr class="w-full h-px my-6 bg-gray-200 border-0 dark:bg-gray-700" />
            //     <span class="absolute text-center font-normal text-gray-400 p-2 !bg-[#1D1D1D] ">{monthYear}</span>
            //   </div>
            <div className="flex flex-col items-start mb-4" key={notification._id}>
              <div className={`text-gray-400 p-3 max-w-[40rem] border-gray-200 rounded-e-xl rounded-es-xl ${notification?.seen ? "bg-[#222222]" : "bg-[#333333]"}`}>
                <p className="text-gray-200">{notification.message}</p>
                <div className="flex justify-between items-baseline w-full">
                  {notification?.questionId && (
                    <MaterialLink
                      className="font-bold"
                      sx={{ cursor: "pointer", color: "#a16207", textDecoration: "none" }}
                    >
                      <Link to={`/question?questionId=${notification.questionId}`}>Solve Question</Link>
                    </MaterialLink>
                  )}
                </div>

                <p className="my-2 text-xs">{handleDate(notification.createdAt)}</p>

                {/* Mark as Read Button */}
                {!notification.seen && (
                  <div className="self-end">
                    <Button
                      variant="contained"
                      onClick={() => handleUpdate(notification._id)}
                      style={{ fontFamily: "inherit" }}
                      className="!text-xs !bg-gray-600 !normal-case"
                    >
                      Mark as Read
                    </Button>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default Notifications
