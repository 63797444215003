import { Tooltip } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import PeopleAltRoundedIcon from "@mui/icons-material/PeopleAltRounded"

const ArenaCard = ({ arena, showDescription = true }) => {
    const navigate = useNavigate()
    return (
        <div
            onClick={() => navigate(`/arenas/${arena?.name}`)}
            key={arena._id}
            className="arena-item rounded-md p-3 cursor-pointer hover:scale-[1.01] transition-all"
        >
            {/* Main Content */}
            <div className="flex flex-col gap-2"> {/* Leave space for badge */}
                <span className="text-gray-200 font-semibold">{arena.name}</span>

                {showDescription && (
                    <div className="arena-description">
                        <p className="font-normal text-gray-400 text-left text-sm leading-6 line-clamp-3">
                            {arena?.description ? arena?.description : "No Description"}
                        </p>
                    </div>
                )}
            </div>

            {/* Tooltip at Bottom */}
            <Tooltip
                placement="top"
                arrow
                title="Learners Enrolled"
            >
                <div className="bg-[#343839] text-[#D0AA45] text-xs font-medium px-2.5 py-1 rounded cursor-default w-min">
                    <p className="text-xs flex">
                        <PeopleAltRoundedIcon sx={{ height: ".8rem", width: ".8rem" }} className="me-1" />
                        <span>{arena.enrolledUsers.length}</span>
                    </p>
                </div>
            </Tooltip>
        </div>

    )
}

export default ArenaCard