import * as React from "react"
import Tabs from "@mui/material/Tabs"
import Tab from "@mui/material/Tab"
import Typography from "@mui/material/Typography"
import Box from "@mui/material/Box"
import RoadMapTimeline from "./RoadMapTimeline"
import { useNavigate } from "react-router-dom"
import { arenaAscent } from "../../../utils/tailwind-css"
import './RoadmapsTab.css'
import '../../../assets/css_files/arenas.css'

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={"span"}>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

export default function RoadMapsTab({ currentArena, handleJoin }) {
  const [value, setValue] = React.useState(0)
  const navigate = useNavigate()

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <Box sx={{ flexGrow: 1, display: "flex" }} className="rounded-md my-2 w-full sticky top-20">
      {/* Tabs - Fixed Sidebar */}
      <div className="shiny-border h-[30rem]">
        <div className="myCard !bg-[#1d1d1d] h-full tabs">
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={value}
            onChange={handleChange}
            sx={{
              borderRight: 1,
              borderColor: "divider",
              position: "relative",
              top: 20,
              marginBottom: 12,
              overflow: "auto", // Allows scrolling inside tabs if needed
              minWidth: "200px", // Set a fixed width for the tabs
            }}
            TabIndicatorProps={{ style: { background: currentArena?.roadmaps?.length > 0 ? arenaAscent : "transparent", color: "#D0AA45" } }}
          >
            {
              currentArena?.roadmaps?.length > 0 ?
                currentArena?.roadmaps?.map((roadmap, i) => (
                  <Tab
                    label={roadmap?.name}
                    key={roadmap?.name}
                    className="!p-2 !capitalize !leading-6"
                    sx={{
                      "&.Mui-selected": {
                        color: "#fff !important",
                      },
                      fontFamily: "inherit",
                      color: "gray !important",
                    }}
                  />
                ))
                :
                <Tab
                  label={"No Roadmaps"}
                  key={"No Roadmaps"}
                  className="!p-2 !capitalize !leading-6"
                  sx={{
                    "&.Mui-selected": {
                      color: "#fff !important",
                    },
                    fontFamily: "inherit",
                    color: "gray !important",
                  }}
                />
            }
          </Tabs>
          {
            currentArena?.roadmaps?.length > 5 &&
            <div className="transparent_gradient overflow-hidden rounded-[18px] !bottom-0"></div>
          }
        </div>
      </div>

      {/* Tab Panel - Scrollable Content */}
      <Box sx={{ flexGrow: 1, overflowY: "auto", height: "calc(100vh-90px)", padding: "0 20px" }}>
        {currentArena?.roadmaps?.map((roadmap, i) => (
          <TabPanel value={value} index={i} className="w-full" key={i}>
            <div className="flex justify-between items-center mb-2">
              <h2 className="text-gray-200 text-base">{roadmap?.name} - {roadmap?.tool}</h2>
              <button
                className="relative inline-flex active:scale-95 transistion overflow-hidden rounded-lg p-[1px] focus:outline-none"
                onClick={currentArena.userEnrolled ? () => navigate(`/arena-question/${currentArena.name}/${roadmap?.name}`) : handleJoin}
              >
                <span className="absolute inset-[-1000%] animate-[spin_2s_linear_infinite] bg-[conic-gradient(from_90deg_at_50%_50%,#e7029a_0%,#f472b6_50%,#bd5fff_100%)]"></span>
                <span className="inline-flex h-full w-full cursor-pointer items-center justify-center rounded-lg bg-black bg-opacity-80 text-sm font-medium text-white backdrop-blur-3xl gap-2 px-4 py-1.5">
                  {currentArena && currentArena.userEnrolled ? "Dive In" : "Enroll"}
                </span>
              </button>
            </div>
            <p className="text-sm text-gray-400 leading-6 mb-4">{roadmap?.description ? roadmap?.description : "No Description"} </p>

            <hr className="h-px my-4 bg-gray-200 border-0 dark:bg-gray-700"></hr>

            <RoadMapTimeline
              handlejoin={handleJoin}
              arenaName={currentArena?.name}
              roadmapName={roadmap?.name}
              roadmap={roadmap}
              isUserEnrolled={currentArena?.userEnrolled}
            />
          </TabPanel>
        ))}
      </Box>
    </Box>

  )
}
