import Carousel from "react-spring-3d-carousel";
import { useState, useEffect } from "react";
import { config } from "react-spring";
import CarouselCard from "./CarouselCard.jsx";

const cardsss = [
  { key: 1, content: <CarouselCard /> },
  { key: 2, content: <CarouselCard /> },
  { key: 3, content: <CarouselCard /> }
];

export default function Carroussel(props) {
  const table = cardsss.map((element, index) => ({
    ...element,
    onClick: () => setGoToSlide(index)
  }));

  const [offsetRadius, setOffsetRadius] = useState(2);
  const [showArrows, setShowArrows] = useState(false);
  const [goToSlide, setGoToSlide] = useState(0); // Start from slide 0
  const [cards] = useState(table);

  useEffect(() => {
    setOffsetRadius(props.offset);
    setShowArrows(props.showArrows);
  }, [props.offset, props.showArrows]);

  // Auto-slide logic
  useEffect(() => {
    const interval = setInterval(() => {
      setGoToSlide((prevSlide) => (prevSlide + 1) % cards.length);
    }, 3000); // Change every 3 seconds

    return () => clearInterval(interval); // Cleanup to prevent memory leaks
  }, [cards.length]);

  return (
    <div
      style={{
        width: props.width,
        height: props.height,
        margin: props.margin,
        height: "220px"
      }}
    >
      <Carousel
        slides={cards}
        goToSlide={goToSlide}
        offsetRadius={3}
        showNavigation={showArrows}
        animationConfig={config.gentle}
      />
    </div>
  );
}
