import React, { useEffect, useState, useRef } from "react"
import { makeStyles } from "@material-ui/core/styles"
import { SUBJECTS, toastConfig } from "../utils/constants"
import { Avatar, Badge, Button, MenuItem, Tooltip } from "@mui/material"
import { marmaOnlyLogo, titleBarLogo } from "../assets/image_files"
import { useSelector, useDispatch } from "react-redux"
import { fetchUserMetrics } from "../redux/actions/usermetrics"
import WestSharpIcon from "@mui/icons-material/WestSharp"
import EastSharpIcon from "@mui/icons-material/EastSharp"
import Badges from "../assets/badges"
import { Link, useNavigate, useParams } from "react-router-dom"
import { AllInclusive, Map, SubdirectoryArrowRightRounded } from "@mui/icons-material"
import { LockOpenIcon } from "@heroicons/react/outline"
import PremiumButton from "./ui/Premiumbutton"
import RewardCoinWithoutAnimation from "./RewardPoint/RewardCoinWithoutAnimation"
import { fetchArenasList, fetchCurrentArenaData, fetchCurrentRoadmapQuestions } from "../redux/actions/arena"
import { getRoadmapQuestions } from "../utils/apis"
import { toast } from "react-toastify"
import ArenaQuestionList from "../assets/arena-question-list"
import ProfileDropdown from "./profile/ProfileDropdown"

const useStyles = makeStyles((theme) => ({
  search: {
    color: "#fefefe",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    width: "auto",
    flexGrow: 1,
  },
  filterContainer: {
    position: "absolute",
    zIndex: "999",
    backgroundColor: "#232627",
    border: "1px solid #FEFEFE1A",
    borderRadius: "4px",
    width: "400px",
    height: "230px",
    color: "#fefefe",
    top: "2.75rem",
    marginTop: "5px",
    display: "flex",
    flexDirection: "column",
    padding: "8px",
  },
  contentArea: {
    overflowY: "auto", // Make only this area scrollable
    flexGrow: 1, // Allow it to take up available space
  },
  arenaItem: {
    marginBottom: "10px",
    marginRight: "20px",
  },
  roadmapItem: {
    cursor: "pointer",
    marginBottom: "10px",
    marginRight: "20px",
  },

  subjectSelect: {
    minWidth: "120px",
    color: "#f2f2f2",
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#D1D1D1",
      },
      "&:hover fieldset": {
        borderColor: "#D1D1D1",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#D1D1D1",
      },
    },
  },
  headerDropdownContainer: {
    position: "absolute",
    zIndex: "10000",
    top: "3.1rem",
    right: "1.25rem",
    //backgroundColor: "#232627",
    //border: "1px solid #FEFEFE1A",
    borderRadius: "18px",
    //padding: "1rem",
    boxShadow: "0 2px 4px rgba(0,0,0,0.2)",
    overflowY: "auto",
    color: "#fefefe",
    marginTop: "1px",
    fontSize: "14px",
  },
  optionStyle: {
    cursor: "pointer",
    margin: 0,
    padding: "0.275rem",
    fontWeight: "500",
    fontSize: "0.875rem",
  },
  arenaNameStyle: {
    color: "#D6EAF8",
    cursor: "pointer",
    margin: 0,
    padding: "0.2rem",
  },
  tab: {
    cursor: "pointer",
    padding: "0.2rem",
    borderRadius: "8px",
    fontWeight: "bold",
    backgroundColor: "#343839",
    fontSize: "0.75rem",
    margin: "3px",
  },
  activeTab: {
    cursor: "pointer",
    padding: "0.2rem",
    borderRadius: "8px",
    fontWeight: "bold",
    backgroundColor: "#343839",
    fontSize: "0.75rem",
    margin: "3px",
    backgroundColor: "#141718",
    color: "#21618C",
  },
  header: {
    display: "flex",
    justifyContent: "flex-start",
    paddingBottom: "0.275rem",
    borderBottom: "1px solid #141718",
    marginLeft: "2px",
    marginRight: "2px",
  },
  roadmapList: {
    display: "none", // hide roadmaps by default
  },
  roadmapListOpen: {
    display: "block",
    transition: "max-height 0.5s ease-in-out",

    overflowY: "auto",
  },
}))

const filterButtonStyle = {
  padding: "0.2rem",
  border: "1px solid #FEFEFE1A",
  cursor: "pointer",
  backgroundColor: "#232627",
  color: "white",
}
const FilterContainer = ({ enrolledArenas }) => {
  const classes = useStyles()
  const [openRoadmaps, setOpenRoadmaps] = useState({}) // store open roadmaps

  const handleArenaClick = (arenaId) => {
    setOpenRoadmaps((prevOpenRoadmaps) => ({
      ...prevOpenRoadmaps,
      [arenaId]: !prevOpenRoadmaps[arenaId],
    }))
  }

  return (
    <div className={classes.filterContainer}>
      {enrolledArenas.map((arena) => (
        <div
          key={arena._id}
          className={classes.arenaNameStyle}
          onClick={() => handleArenaClick(arena._id)}>
          <h4 style={{ color: "#fefefe", fontWeight: "bold" }}>{arena.name}</h4>
          <ul className={`${classes.roadmapList} ${openRoadmaps[arena._id] ? classes.roadmapListOpen : ""}`}>
            {arena.roadmaps.map((roadmap) => (
              <li
                key={roadmap.id}
                className={classes.optionStyle}>
                <SubdirectoryArrowRightRounded className="!text-base" /> {roadmap.name}
              </li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  )
}

const ArenaQuestionHeader = ({ primaryTool, onSubmit, handleQuesSwitch, handleSwitchtoExactQues, stopTimer, startTimer, questionMeta }) => {
  const classes = useStyles()
  const [historyVisible, setHistory] = useState(false)
  const [showDropDown, setShowDropdown] = useState(false)
  const [isFilterVisible, setIsFilterVisible] = useState(false)
  const [userSearch, setSearch] = useState("")
  const [prevQue, setPrevQue] = useState(-1)
  const navigate = useNavigate()

  const dispatch = useDispatch()

  const reduxUser = useSelector((state) => state.user.userDetails)
  const userMetrics = useSelector((state) => state.userMetrics.userMetrics)
  const enrolledArenas = useSelector((state) => state.arena.enrolledArenas)
  const currentArena = useSelector((state) => state.arena.currentArena)
  const currentRoadMapQues = useSelector(state => state.arena.currentRoadMapQuestions)
  const [currentRoadmap, setCurrentRoadmap] = useState()
  const headerDropdownRef = useRef(null)
  const questionListRef = useRef(null)
  const [questionHistory, setQuestionHistory] = useState([]);

  const { name, roadmap } = useParams()
  const dropdownRef = useRef(null)
  const currentArenaIndex = useRef(null)

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsFilterVisible(false) // Close dropdown if clicking outside
      }
    }
    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [dropdownRef])

  useEffect(() => {
    if (questionMeta && !questionMeta.rewardPoints) {
      if (historyVisible) stopTimer()
      else startTimer()
    }
  }, [historyVisible])

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (questionListRef.current && !questionListRef.current.contains(event.target)) {
        setHistory(false)
      }
    }

    document.addEventListener("mousedown", handleClickOutside)

    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [questionListRef])

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (headerDropdownRef.current && !headerDropdownRef.current.contains(event.target)) {
        setShowDropdown(false) // Close dropdown if clicking outside
      }
    }
    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [headerDropdownRef])

  useEffect(() => {
    dispatch(fetchUserMetrics())
    dispatch(fetchArenasList())
    dispatch(fetchCurrentArenaData(name))
  }, [dispatch])

  useEffect(() => {
    if (Object.keys(currentArena).length > 0) {
      const filteredRoadmap = currentArena.roadmaps.find(
        (roadmapItem) => roadmapItem.name === decodeURIComponent(roadmap)
      );
      if (filteredRoadmap) {
        setCurrentRoadmap(filteredRoadmap);
      }
    }
  }, [currentArena, roadmap]);

  useEffect(() => {
    // If the redux store already has questions, use them.
    if (currentRoadMapQues && currentRoadMapQues.length > 0) {
      setQuestionHistory(currentRoadMapQues);
    } else if (currentRoadmap) {
      // Fallback: dispatch to fetch questions if not present.
      dispatch(fetchCurrentRoadmapQuestions(name, currentRoadmap.name, currentRoadmap.description))
        .then((response) => {
          // Optional: you can update local state here if needed.
          setQuestionHistory(response?.data?.questionStatements || []);
        })
        .catch((e) => toast.error(e, toastConfig));
    }
  }, [currentRoadMapQues, currentRoadmap, dispatch, name, questionMeta]);

  useEffect(() => {
    if (userMetrics?.arenas) {
      currentArenaIndex.current = userMetrics.arenas.findIndex((arena) => arena.arenaId === currentArena._id)
    }
  }, [currentArena, userMetrics, questionMeta])



  const logout = () => {
    localStorage.removeItem("usertoken")
    localStorage.removeItem("hasShownFreeArenaPopup")
    localStorage.removeItem("refreshToken")
    window.location.reload()
  }

  const handleQuestionsHistory = (questionId) => {
    handleSwitchtoExactQues(questionId)
  }

  console.log(userMetrics?.badges?.length)

  return (
    <>
      <div className="flex flex-col h-[90px] fixed top-0 bg-[#232627] w-full z-[999]">
        <div className="flex flex-row justify-between items-center my-1 px-[20px]">
          <div className="flex flex-row items-center justify-start">
            <img
              src={marmaOnlyLogo}
              alt="Marma AI"
              className="h-6 object-fit bg-transparent mr-3"
              onClick={() => navigate("/home")}
            />
            <Tooltip
              title="Switch to Previous Question"
              placement="bottom-start">
              <button
                onClick={() => handleQuesSwitch("previous")}
                disabled={prevQue === 0 || questionMeta?.isFirstQuestion}>
                {" "}
                <WestSharpIcon sx={{ color: "#fefefe", height: "18px", width: "18px" }} />
              </button>
            </Tooltip>
            <Tooltip
              title="Switch to Next Question"
              placement="bottom-start">
              <button onClick={() => handleQuesSwitch("next")} disabled={questionMeta?.isLastQuestion}>
                {" "}
                <EastSharpIcon sx={{ color: "#fefefe", height: "18px", width: "18px" }} />{" "}
              </button>
            </Tooltip>

            <div
              className="w-[400px]"
              ref={dropdownRef}>
              <div className=" bg-[#343839] h-[1.75rem] rounded-full flex flex-row items-center overflow-hidden">
                <div className="flex">
                  <span className="relative inline-block overflow-hidden rounded-full p-[1px]">
                    <span className="absolute inset-[-1000%] animate-[spin_2s_linear_infinite] bg-[conic-gradient(from_90deg_at_50%_50%,#a9a9a9_0%,#0c0c0c_50%,#a9a9a9_100%)] dark:bg-[conic-gradient(from_90deg_at_50%_50%,#171717_0%,#737373_50%,#171717_100%)]"></span>
                    <div className="inline-flex h-full w-full cursor-default justify-center rounded-full bg-white px-3 py-1 text-xs font-medium leading-5 text-slate-600 backdrop-blur-xl dark:bg-gray-700 !bg-opacity-20 dark:text-slate-200">Arena</div>
                  </span>
                </div>

                <input
                  type="search"
                  value={userSearch}
                  onChange={(e) => setSearch(e.target.value)}
                  className="px-5 placeholder-gray-400 w-full bg-[#343839] focus:outline-none text-sm text-gray-400"
                  placeholder={`You're in ${name} - ${roadmap}`}
                  onFocus={() => setIsFilterVisible(true)}
                />
              </div>
              {isFilterVisible && <FilterContainer enrolledArenas={enrolledArenas} />}
            </div>
          </div>

          <nav className="navigation flex items-center justify-evenly">
            {/* badges */}
            {
              userMetrics?.badges?.length !== 0 || (userMetrics && currentArenaIndex?.current !== -1) &&
              <div className="bg-[#343839] text-[#D0AA45] text-xs font-medium px-2.5 py-0.5 rounded flex gap-3 justify-between items-center cursor-default">

                <Tooltip
                  placement="bottom-start"
                  arrow
                  title="Reward Points">
                  {userMetrics && currentArenaIndex.current !== -1 && (
                    <p className="inline-flex text-sm gap-1 justify-center items-center">
                      {" "}
                      <RewardCoinWithoutAnimation />
                      {userMetrics?.arenas[currentArenaIndex.current]?.totalRewardPoints}
                    </p>
                  )}
                </Tooltip>

                {userMetrics?.badges?.length !== 0 && <Badges badges={userMetrics?.badges} />}
              </div>
            }
            <Link to="/question">Free Style</Link>
            <Link to="/dashboard">Dashboard</Link>
            <Link to="/arenas">Arenas</Link>
            <div ref={headerDropdownRef}>
              {reduxUser ? (
                <Avatar
                  onClick={() => setShowDropdown(!showDropDown)}
                  className=" !bg-[#343839] !capitalize !text-lg !font-semibold !cursor-pointer">
                  {" "}
                  {reduxUser.name.split(" ")[0][0]}
                </Avatar>
              ) : (
                <Avatar
                  onClick={() => setShowDropdown(!showDropDown)}
                  className=" !bg-[#343839] !capitalize !text-lg !font-semibold !cursor-pointer"
                  src={titleBarLogo}>
                  {" "}
                </Avatar>
              )}
              {showDropDown && (
                <ProfileDropdown />
              )}
            </div>
          </nav>
        </div>
        <div className="flex flex-row justify-start items-center my-2 px-[10px]">
          <div className="text-gray-200 bg-gray-500 rounded-lg px-2.5 py-1.5 ms-2 bg-opacity-20 text-sm">
            Tool for Roadmap :<span className="text-gray-400"> {primaryTool}</span>
          </div>
          <div className="flex-row-container ml-auto gap-2">
            {reduxUser && !reduxUser.subscription.isActive && <PremiumButton questionsSeen={questionHistory.length} />}
            {reduxUser && reduxUser.subscription.isActive && (
              <Button
                onClick={() => navigate("/pricing")}
                style={{ ...filterButtonStyle, fontFamily: "inherit" }}
                //disabled={questionHistory.current.filter((question) => question.status === "correct").length === 0}
                className="!text-sm !text-gray-400 !capitalize !px-2 !py-1.5 hover:!text-gray-200">
                <AllInclusive className="!text-base" />
                Premium
              </Button>
            )}
            <Badge
              badgeContent={questionHistory?.filter((question) => question.status === "correct").length}
              color="primary"
              className="!ml-auto history-button !justify-self-end me-3"
              sx={{
                "& .MuiBadge-badge": {
                  color: "#e5e7eb",
                  backgroundColor: "#4b5563",
                },
              }}>
              <Tooltip
                placement="bottom"
                title="Click to See your history"
                arrow>
                <Button
                  onClick={() => setHistory(!historyVisible)}
                  style={{ ...filterButtonStyle, fontFamily: "inherit" }}
                  //disabled={questionHistory.current.filter((question) => question.status === "correct").length === 0}
                  className="!text-sm !text-gray-400 !capitalize !px-2 !py-1.5 hover:!text-gray-200">
                  <Map className="!text-base" /> Roadmap
                </Button>
              </Tooltip>
            </Badge>
          </div>
          {historyVisible && (
            <div
              //ref={questionListRef}
              className="w-full">
              <ArenaQuestionList
                isOpen={historyVisible}
                onClose={() => setHistory(false)}
                questionHistory={questionHistory}
                handleQuestionsHistory={handleQuestionsHistory}
                currentQuestionId={window.location.href.split("?questionId=")[1] || ""}
              />
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default React.memo(ArenaQuestionHeader)
