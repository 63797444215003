import React, { useCallback, useEffect, useState } from 'react'
import LevelCard from './LevelCard'
import { useDispatch, useSelector } from 'react-redux'
import { fetchLimits } from '../../redux/actions/free-limits'
import { Backdrop, MenuItem } from '@mui/material'
import { CircularProgress } from '@material-ui/core'
import StorageIcon from '@mui/icons-material/Storage';
import CodeIcon from '@mui/icons-material/Code';
import BorderAllIcon from '@mui/icons-material/BorderAll';
import { levelwiseTopics } from '../../utils/levelwiseTopics'
const images = [
  "https://miro.medium.com/v2/resize:fit:702/1*Ra02AqsQlC0KV229EvM98g.png",
  "https://lpsonline.sas.upenn.edu/sites/default/files/2022-10/plpso-feratures-data-business.jpg",
  "https://onlinedegrees.sandiego.edu/wp-content/uploads/2022/11/what-is-data-analytics-mads.jpg",
  "https://www.future-processing.com/blog/wp-content/uploads/2024/01/cover_data-ai.jpg",
  "https://seleritysas.com/wp-content/uploads/2019/12/shutterstock_606583169.jpg",
  "https://assets.datamation.com/uploads/2024/01/dm_20240124-data-analytics-techniques.png"
]
const LevelsPage = ({ onClose, open }) => {

  const [currentTool, setCurrentTool] = useState("SQL")
  const [levelData, setLevelData] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [showModal, setShowModal] = useState(false)
  let levelWiseTopic = levelwiseTopics[currentTool];
  const dispatch = useDispatch()
  const { freeLimits, userDetails } = useSelector(
    useCallback(
      (state) => ({
        freeLimits: state.freeLimits,
        userDetails: state.user.userDetails,
      }),
      []
    )
  )

  useEffect(() => {
    setIsLoading(true)
    dispatch(fetchLimits())
  }, [])


  function sortLevelWise(data) {
    return Object.entries(data)
      .sort(([levelA], [levelB]) => {
        const numA = parseInt(levelA.replace('level', ''), 10);
        const numB = parseInt(levelB.replace('level', ''), 10);
        return numA - numB;
      })
      .reduce((sortedData, [level, values]) => {
        sortedData[level] = values;
        return sortedData;
      }, {});
  }

  useEffect(() => {
    setLevelData(sortLevelWise(freeLimits.freeLimits[currentTool.toLowerCase()]))
    levelWiseTopic = levelWiseTopic[currentTool]
    console.log(levelWiseTopic)
    setIsLoading(false)
  }, [freeLimits, currentTool])

  const renderLevelCards = () => {
    return Object.entries(levelData).map(([level, { totalFreeQuestions, remainingFreeQuestions }]) => {
      const isSubscribed = userDetails?.subscription?.isActive;
      const isLimitOver = !isSubscribed && remainingFreeQuestions === 0;
      const currLevel = level.split("level")[1];

      return (
        <LevelCard
          key={level}
          level={currLevel}
          tool={currentTool}
          imgUrl={images[Math.floor(Math.random() * images.length)]}
          isLimitOver={isLimitOver}
          remainingQuestions={remainingFreeQuestions}
          totalQuestions={totalFreeQuestions}
          lockPosition={(totalFreeQuestions / 80) * 100} // Update the divisor later
          levelCompletedPercentage={((totalFreeQuestions - remainingFreeQuestions) / 80) * 100}
          setShowModal={setShowModal}
          desc={levelWiseTopic[currLevel]}
          isSubscribed={isSubscribed}
        />
      );
    });
  };

  return (
    <Backdrop
    sx={(theme) => ({ color: '#fff' })}
    open={open}
    onClick={onClose}
    className='z-[10000]'
    >
      <div className='px-4 pb-4 bg-[#232627] bg-opacity-90 shadow-lg rounded-lg h-3/4 overflow-auto' onClick={(e) => e.stopPropagation()}>
        {
          showModal &&
          <div className='fixed top-0 left-0 bg-black bg-opacity-50 h-screen w-full z-[1000000] flex justify-center items-center text-white'>
            <CircularProgress color="inherit" />
          </div>
        }

        <h2 className="text-2xl text-white mt-2 text-center">Select Level</h2>

        <div className="flex gap-2 items-center justify-center my-4">
          <span className="text-gray-400">Choose Tools</span>
          <ToolButton tool="SQL" currentTool={currentTool} setCurrentTool={setCurrentTool} Icon={StorageIcon} label="SQL" />
          <ToolButton tool="Python" currentTool={currentTool} setCurrentTool={setCurrentTool} Icon={CodeIcon} label="Python" />
          <ToolButton tool="excel" currentTool={currentTool} setCurrentTool={setCurrentTool} Icon={BorderAllIcon} label="Excel" />
        </div>

        {/* Grid Layout for Cards */}
        <div className="flex justify-center items-center">
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-6">
            {/* Loading state - consider using a spinner for better UX */}
            {isLoading ? (
              <div className="col-span-full text-center text-white py-10">
                <p>Loading...</p>
              </div>
            ) : (
              renderLevelCards()
            )}
          </div>
        </div>
      </div>
    </Backdrop>
  )
}

export default LevelsPage;


const ToolButton = ({ tool, currentTool, setCurrentTool, Icon, label }) => (
  <button
    className={`cursor-pointer flex items-center hover:bg-[#313435] rounded-md transition-all p-2 ${currentTool === tool ? "bg-[#343839]" : "bg-transparent"}`}
    onClick={() => setCurrentTool(tool)}
  >
    <Icon className={`${currentTool === tool ? "!text-gray-200" : "!text-gray-400"} !text-base`} />
    <span className={`${currentTool === tool ? "!text-gray-200 font-semibold" : "!text-gray-400"}`}>{label}</span>
  </button>
);