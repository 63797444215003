import React, { useEffect, useState, useRef } from "react"
import { makeStyles } from "@material-ui/core/styles"
import Filter from "../assets/filter"
import Modal from "../assets/modal"
import { SUBJECTS } from "../utils/constants"
import { Avatar, Badge, Button, MenuItem, Tooltip } from "@mui/material"
import { marmaOnlyLogo, titleBarLogo } from "../assets/image_files"
import { useSelector, useDispatch } from "react-redux"
import { fetchQuestionHistory } from "../redux/actions/questionhistory"
import { fetchUserMetrics } from "../redux/actions/usermetrics"
import QuestionList from "../assets/question-list"
import WestSharpIcon from "@mui/icons-material/WestSharp"
import EastSharpIcon from "@mui/icons-material/EastSharp"
import Badges from "../assets/badges"
import { Link, useMatch, useNavigate } from "react-router-dom"
import { AllInclusive, KeyboardArrowDown, KeyboardArrowUp, Map, SubjectOutlined } from "@mui/icons-material"
import { AdjustmentsIcon, LockOpenIcon, MenuIcon } from "@heroicons/react/outline"
import Dropdown from "./ui/Dropdown"
import PremiumButton from "./ui/Premiumbutton"
import RewardCoinWithoutAnimation from "./RewardPoint/RewardCoinWithoutAnimation"
import LockingBar from "./ui/LockingBar"
import { Modal as MUIModal } from '@mui/material';
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import LevelsPage from "./level_list/LevelsPage"
import MobileMenu from "./ui/menu/MobileMenu"
import { logout } from "../utils/common-function"
import ProfileDropdown from "./profile/ProfileDropdown"

const useStyles = makeStyles((theme) => ({
  search: {
    width: "50vw",
    color: "#fefefe",
    marginLeft: "",
  },
  headerDropdownContainer: {
    position: "absolute",
    zIndex: "10000",
    top: "3.1rem",
    right: "1.25rem",
    //backgroundColor: "#232627",
    //border: "1px solid #FEFEFE1A",
    borderRadius: "18px",
    //padding: "1rem",
    boxShadow: "0 2px 4px rgba(0,0,0,0.2)",
    overflowY: "auto",
    color: "#fefefe",
    marginTop: "1px",
    fontSize: "14px",
  },
}))

const filterButtonStyle = {
  padding: "0.2rem",
  border: "1px solid #FEFEFE1A",
  cursor: "pointer",
  backgroundColor: "#232627",
  color: "white",
}

const Header = ({ levels, primaryTool, selectedLevel, onChangeSubject, onChangeLevel, searchFilterList, onSearchFilter, selectedTopic, questionMeta, handleQuesSwitch, handleSwitchtoExactQues, stopTimer, startTimer, modifyFilterList, fromURL, loader, showToolbar, setShowToolbar }) => {
  const classes = useStyles()
  const [levelData, setLevelData] = useState([])
  const [prevQue, setPrevQue] = useState(-1)
  const [historyVisible, setHistory] = useState(false)
  const [showDropDown, setShowDropdown] = useState(false)
  const [showLevelModal, setShowLevelModal] = useState(false)
  const [openMobileNav, setOpenMobileNav] = useState(false)

  const navigate = useNavigate()

  const dispatch = useDispatch()
  const questionHistory = useSelector((state) => state.questionHistory.questionHistory)
  const reduxUser = useSelector((state) => state.user.userDetails)
  const userMetrics = useSelector((state) => state.userMetrics.userMetrics)
  const freeLimits = useSelector((state) => state.freeLimits.freeLimits[primaryTool?.toLowerCase()])
  const isPremiumUser = reduxUser?.subscription?.isActive

  const headerDropdownRef = useRef(null)
  const questionListRef = useRef(null)
  const getSolvedQuestionsCountByLevel = (questionsData, tool) => {
    return questionsData.reduce((acc, question) => {
      if (question.ToolSelected === tool && question.isAnswerAttempted && question.isAnswerCorrect) {
        const level = question.Level[tool] // Get the level for the given tool
        acc[level] = (acc[level] || 0) + 1
      }
      return acc
    }, {})
  }

  useEffect(() => {
    if (questionMeta && !questionMeta.rewardPoints) {
      if (historyVisible) stopTimer()
      else startTimer()
    }
  }, [historyVisible])

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (questionListRef.current && !questionListRef.current.contains(event.target)) {
        setHistory(false)
      }
    }

    document.addEventListener("mousedown", handleClickOutside)

    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [questionListRef])

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (headerDropdownRef.current && !headerDropdownRef.current.contains(event.target)) {
        setShowDropdown(false) // Close dropdown if clicking outside
      }
    }
    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [headerDropdownRef])

  useEffect(() => {
    dispatch(fetchUserMetrics())
  }, [dispatch])

  useEffect(() => {
    dispatch(fetchQuestionHistory())
  }, [dispatch])

  useEffect(() => {
    if (questionHistory && questionHistory.length > 0 && primaryTool != null) {
      const solvedQuestionsByLevel = getSolvedQuestionsCountByLevel(questionHistory, primaryTool)
      setLevelData(solvedQuestionsByLevel)
    }
  }, [questionHistory])



  const handleQuestionsHistory = (questionId, toolName, level) => {
    //console.log({ questionId, toolName, level })
    handleSwitchtoExactQues(questionId, toolName, level)
  }

  const [levelCompletedPercentage, setLevelCompletedPercentage] = useState()
  const [lockPosition, setLockPosition] = useState()

  useEffect(() => {
    const particularFreeLimit = questionMeta && freeLimits[`level${questionMeta?.Level?.[primaryTool]}`]
    setLevelCompletedPercentage(levelData[`${questionMeta?.Level[primaryTool]}`] ? Math.floor((parseInt(levelData[`${questionMeta?.Level[primaryTool]}`]) / 50) * 100) : 0)
    setLockPosition(Math.floor((parseInt((particularFreeLimit?.["totalFreeQuestions"] / 80) * 100)))) // 50 ko change kro

    console.log(questionMeta, "==META")
  }, [freeLimits, questionMeta])

  return (
    <>
      {
        openMobileNav &&
        <MobileMenu questionMeta={questionMeta} primaryTool={primaryTool} onClose={setOpenMobileNav} fromURL={fromURL}
          topics={searchFilterList.functions}
          selectedTopic={selectedTopic}
          onSubmit={onSearchFilter}
          modifyFilterList={modifyFilterList}
          searchFilterList={searchFilterList}
        />
      }


      <LevelsPage onClose={() => setShowLevelModal(false)} open={showLevelModal} />

      <div className={`flex flex-col fixed top-0  bg-[#232627] w-full z-[999] ${showToolbar ? "h-[90px]" : " h-[50px] duration-500"} transition-all ease-in-out`}>
        <div className="flex flex-row justify-between items-center my-[0.125rem] px-[20px]">
          <div className="flex flex-row items-center justify-start">
            <img
              src={marmaOnlyLogo}
              alt="Marma AI"
              className="h-6 object-fit bg-transparent mr-3"
              onClick={() => navigate("/home")}
            />
            <Tooltip
              title="Switch to Previous Question"
              placement="bottom-start">
              <button
                onClick={() => handleQuesSwitch("previous")}
                disabled={prevQue === 0 || questionMeta?.initialQuestion}>
                {" "}
                <WestSharpIcon sx={{ color: "#fefefe", height: "18px", width: "18px" }} />
              </button>
            </Tooltip>
            <Tooltip
              title="Switch to Next Question"
              placement="bottom-start">
              <button onClick={() => handleQuesSwitch("next")}>
                {" "}
                <EastSharpIcon sx={{ color: "#fefefe", height: "18px", width: "18px" }} />{" "}
              </button>
            </Tooltip>

            <div className="hidden sm:hidden md:flex">
              <Filter
                fromURL={fromURL}
                topics={searchFilterList.functions}
                selectedTopic={selectedTopic}
                onSubmit={onSearchFilter}
                modifyFilterList={modifyFilterList}
              />
            </div>
          </div>

          <nav className="navigation items-center justify-evenly hidden sm:hidden lg:flex">
            {/* badges */}
            <div className="bg-[#343839] text-[#D0AA45] text-xs font-medium px-2.5 py-0.5 rounded flex gap-3 justify-between items-center cursor-default">
              <Tooltip
                placement="bottom-start"
                arrow
                title="Reward Points">
                {userMetrics ? (
                  <p className="inline-flex text-sm gap-1 justify-center items-center">
                    {" "}
                    <RewardCoinWithoutAnimation />
                    {userMetrics.totalRewardPoints}
                  </p>
                ) : (
                  ""
                )}
              </Tooltip>

              {
                questionMeta &&

                <Tooltip
                  placement="bottom-start"
                  arrow
                  title="Current Level">
                  <p className="text-sm"> {questionMeta && `Level ${questionMeta?.Level[primaryTool]}`}</p>
                </Tooltip>
              }

              {userMetrics?.badges?.length !== 0 && <Badges badges={userMetrics?.badges} />}
            </div>
            <Link to="/dashboard">Dashboard</Link>
            <Link to="/arenas">Arenas</Link>
            {
              !showToolbar &&
              <Tooltip
                placement="bottom-start"
                arrow
                title="Show Tool Bar">
                <span onClick={() => setShowToolbar(true)} className="cursor-pointer">
                  <KeyboardArrowDown className="text-white hover:!text-gray-400 !transition-all" />
                </span>
              </Tooltip>
            }

            <div ref={headerDropdownRef}>
              {reduxUser ? (
                <Avatar
                  onClick={() => setShowDropdown(!showDropDown)}
                  className=" !bg-[#1D1D1D] my-1 !capitalize !text-lg !font-semibold !cursor-pointer hover:!bg-gray-700 transition-all">
                  {" "}
                  {reduxUser.name.split(" ")[0][0]}
                </Avatar>
              ) : (
                <Avatar
                  onClick={() => setShowDropdown(!showDropDown)}
                  className=" !bg-[#1D1D1D] my-1 !capitalize !text-lg !font-semibold !cursor-pointer hover:!bg-gray-700 transition-all"
                  src={titleBarLogo}>
                  {" "}
                </Avatar>
              )}
              {showDropDown && (
                <ProfileDropdown />
              )}
            </div>

          </nav>

          <div className="w-4 h-4 block sm:block lg:hidden" onClick={() => { setOpenMobileNav(true) }}>
            <MenuIcon className="!text-white" />
          </div>
        </div>

        <div className={`flex flex-row justify-start items-center my-2 px-[10px] ${showToolbar ? " animate__animated animate__fadeIn flex" : "animate__animated animate__fadeOut hidden"}`}>
          <div
            id="subject-dropdown"
            className="me-3 ms-3">
            <Dropdown
              title={primaryTool || "Tools"}
              tooltip={`Primary tool selected for the situation is ${primaryTool}`}
              icon={<SubjectOutlined />}
              children={SUBJECTS.map((subject) => (
                <MenuItem
                  key={subject}
                  onClick={() => {
                    subject !== primaryTool && onChangeSubject(subject)
                  }}
                  className="!text-gray-200 !font-light !text-xs">
                  {subject}
                </MenuItem>
              ))}
            />
          </div>

          <div id="level-dropdown">
            <Dropdown
              title={
                loader
                  ? "loading..."
                  : questionMeta === null
                    ? "Choose Level"
                    : questionMeta?.Level?.[primaryTool]
                      ? `Level ${questionMeta?.Level[primaryTool]}`
                      : "Level"
              }


              tooltip={`Choose difficulty level here`}
              icon={<AdjustmentsIcon className="h-5 w-5 text-gray-400" />}
              children={
                levelData.length === 0 ? (
                  <>
                    {levels.map((item) => (
                      <MenuItem
                        className={`!text-gray-200 !font-light !text-xs' ${selectedLevel === item ? "border-[2px] font-bold" : ""} `}
                        onClick={() => item !== selectedLevel && onChangeLevel(item)}
                        key={item}>
                        {item}
                      </MenuItem>
                    ))}
                    <MenuItem
                      className={`!text-gray-200 !font-light !text-xs !flex !justify-end`}
                      onClick={() => setShowLevelModal(true)}
                    >
                      <ExpandCircleDownIcon />
                    </MenuItem>
                  </>
                ) : (
                  <>
                    {levels.map((item) => {
                      // Calculate fill percentage based on the index
                      const fillPercentage = levelData?.[`${item}`] ? Math.floor((parseInt(levelData?.[`${item}`]) / 80) * 100) : 0
                      const lockPosition = questionMeta && Math.floor((parseInt((freeLimits?.[`level${item}`]?.["totalFreeQuestions"] / 80) * 100)))
                      const limit = freeLimits?.['level' + item]
                      return (
                        <MenuItem
                          // onClick={handleClose}
                          className="flex gap-3"
                          onClick={() => item !== questionMeta?.Level[primaryTool] && onChangeLevel(item)}
                          key={item}>
                          <div className="flex justify-evenly items-center gap-3 w-full">
                            <button className="p-0 text-gray-200 font-light text-xs w-[1rem]">{item}</button>
                            <LockingBar levelCompletedPercentage={fillPercentage} lockPosition={lockPosition} showLock={!isPremiumUser} />
                            {
                              !isPremiumUser &&
                              <>
                                <Tooltip
                                  placement="bottom-start"
                                  arrow
                                  title={`You have ${limit?.remainingFreeQuestions} free question${limit?.remainingFreeQuestions !== 1 ? 's' : ''} remaining out of ${limit?.totalFreeQuestions} total questions.`}>
                                  <span className={` text-xs ${limit?.remainingFreeQuestions === 0 ? 'text-red-400' : 'text-gray-400'}`}>
                                    {limit?.remainingFreeQuestions > 0 ? <LockOpenOutlinedIcon className="!text-xs" /> : <LockOutlinedIcon className="!text-xs" />}{" "}
                                    {limit?.remainingFreeQuestions}/{limit?.totalFreeQuestions}
                                  </span>
                                </Tooltip>
                              </>
                            }

                          </div>
                        </MenuItem>
                      )
                    })}

                    <MenuItem
                      className={`!text-gray-200 !font-light !text-xs !flex !justify-end`}
                      onClick={() => setShowLevelModal(true)}
                    >
                      <ExpandCircleDownIcon />
                    </MenuItem>
                  </>
                )
              }
            />
          </div>

          {questionMeta?.Level?.[primaryTool] ? (
            <Tooltip
              title={`Your progress for ${primaryTool} level ${questionMeta?.Level[primaryTool]}`}
              placement="bottom"
              arrow>
              <div className="ms-3 relative w-28">
                <LockingBar levelCompletedPercentage={levelCompletedPercentage} lockPosition={lockPosition} />
              </div>
            </Tooltip>
          ) : loader ? (
            <div
              role="status"
              className="max-w-sm animate-pulse">
              <div className="w-[100px] ms-3 rounded-full h-2.5 bg-gray-700"></div>
            </div>
          )
            : null}

          <div className="flex-row-container ml-auto gap-2">
            {reduxUser && !reduxUser.subscription.isActive && <PremiumButton questionsSeen={questionHistory.length} />}
            {reduxUser && reduxUser.subscription.isActive && (
              <Button
                onClick={() => navigate("/pricing")}
                style={{ ...filterButtonStyle, fontFamily: "inherit" }}
                //disabled={questionHistory.current.filter((question) => question.status === "correct").length === 0}
                className="!text-sm !text-gray-400 !capitalize !px-2 !py-1.5 hover:!text-gray-200">
                <AllInclusive className="!text-base" />
                Premium
              </Button>

            )}
            <Tooltip
              placement="bottom"
              title="Click to See your history"
              arrow>
              <Button
                onClick={() => setHistory(!historyVisible)}
                style={{ ...filterButtonStyle, fontFamily: "inherit" }}
                disabled={questionHistory.filter((question) => question.isAnswerAttempted).length === 0}
                className="!text-sm !text-gray-400 !capitalize !px-2 !py-1.5 hover:!text-gray-200">
                <Map className="!text-base" /> Journey
                <span className="bg-gray-100 text-gray-800 text-xs font-medium px-2 py-0.5 rounded-full dark:bg-gray-700 dark:text-gray-300">{questionHistory.filter((question) => question.isAnswerCorrect).length}</span>
              </Button>
            </Tooltip>

            <Tooltip
              placement="bottom-start"
              arrow
              title="Hide Tool Bar">
              <span onClick={() => setShowToolbar(false)} className="cursor-pointer ">
                <KeyboardArrowUp className="text-white hover:!text-gray-400 !transition-all" />
              </span>
            </Tooltip>
          </div>
          {historyVisible && (
            <div
              //ref={questionListRef}
              className="w-full">
              <QuestionList
                isOpen={historyVisible}
                onClose={() => setHistory(false)}
                questionHistory={questionHistory}
                handleQuestionsHistory={handleQuestionsHistory}
                currentQuestionId={questionMeta?.userQuestionHistoryId || ""}
              />
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default Header
