import { useEffect } from "react"
import { Link } from "react-router-dom"
import CommonHeader from "../assets/common-header"

import { useNavigate } from "react-router-dom"
import { makeStyles } from "@material-ui/core/styles"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"

import WestSharpIcon from "@mui/icons-material/WestSharp"
import EastSharpIcon from "@mui/icons-material/EastSharp"
import StarsIcon from "@mui/icons-material/Stars"

import ArenaGraphics from '../assets/image_files/main_home_page/arena-graphics.png'
import FreeStyleGraphics from '../assets/image_files/main_home_page/free-style-graphics.png'


const useStyles = makeStyles((theme) => ({
  card: {
    maxWidth: 345,
    height: 200,
    margin: theme.spacing(2),
    boxShadow: "0px 0px 10px rgba(0,0,0,0.2)",
    backgroundColor: "transparent",
    borderRadius: "8px",
    border: "0.5px solid #fefefe1a",
    color: "#fefefe",
    "&:hover": {
      boxShadow: "0px 0px 15px rgba(0,0,0,0.3)",
    },
  },
  cardContent: {
    padding: theme.spacing(2),
  },
  button: {
    margin: theme.spacing(1),
    padding: theme.spacing(1),
    borderRadius: "50%",
    backgroundColor: "transparent",
    border: "none",
    "&:hover": {
      backgroundColor: "rgba(0,0,0,0.1)",
    },
    textAlign: "center",
  },
  icon: {
    fontSize: 40,
    color: "#fefefe",
  },
}))

const MainPage = () => {
  const classes = useStyles()

  return (
    <div className="post-login-pages-main-container">
      <CommonHeader />
      <div className="flex flex-col justify-center items-center">

        <h2 className="mb-5 text-4xl font-bold">Solve In</h2>
        <div className="flex gap-6">
          <Link to="/question">
            <img className="h-[350px] hover:scale-[1.03] transition-all" src={FreeStyleGraphics} alt="free-style-graphcis" />
          </Link>

          <Link to="/arenas">
          <img className="h-[350px] hover:scale-[1.03] transition-all" src={ArenaGraphics} alt="arena-graphcis" />
          </Link>
        </div>

      </div>
    </div>
  )
}

export default MainPage
