import React, { useState, useEffect } from "react"
import { getBasicInfo, updateBasicInfo } from "../utils/apis" // Import the API functions
import { toast } from "react-toastify"
import { toastConfig } from "../utils/constants"
import Loader from "./loader"
import { faCancel, faEdit, faUpload } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button } from "@mui/material"
import { validateGitHubUrl, validateLinkedInUrl, isValidUrl } from "../utils/common-function"
import { AddCircleOutlineRounded, Cancel, Delete, TaskAlt } from "@mui/icons-material"
import ToastError from "../components/toasts/ToastError"
import ToastSuccess from "../components/toasts/ToastSuccess"
import { useSelector } from "react-redux"
import LineDivider from "../components/LineDivider"
import VerifiedIcon from '@mui/icons-material/Verified';
const genders = ["Male", "Female", "Others"]

const BasicInfo = () => {
  const reduxUser = useSelector((state) => state.user.userDetails)

  const name = reduxUser?.name;
  const [user, setUser] = useState({
    name: name,
    gender: "",
    birthday: new Date(),
    linkedin: "",
    github: "",
    website: "",
    summary: "",
    skills: "",
    location: "",
    experiences: [],
    education: [],
  })
  const [loading, setLoading] = useState(true) // Initially true
  const [isEditable, setIsEditable] = useState(false)
  const [newExperience, setNewExperience] = useState({})
  const [newEducation, setNewEducation] = useState({})
  const [currSkill, setCurrSkill] = useState()
  const [initialData, setInitialData] = useState({ name: name, gender: "", birthday: new Date(), linkedin: "", github: "", website: "", summary: "", skills: "", location: "", experiences: [], education: [] })

  useEffect(() => {
    const fetchUserData = async () => {
      setLoading(true)
      const response = await getBasicInfo() // Fetch initial data
      if (response.status === 200 && response.data) {
        const responseData = response.data
        const revisedResponseData = {
          name: name,
          gender: responseData.gender,
          birthday: formatDateToDisplay(response?.data?.dateOfBirth?.slice(0, 10)),
          linkedin: responseData.linkedInUrl,
          github: responseData.githubUrl,
          website: responseData.website,
          summary: responseData.summary,
          skills: responseData.technicalSkills,
          location: responseData.location,
          experiences: responseData.workExperience.map((item) => ({ ...item, startDate: formatDateToDisplay(item?.startDate?.slice(0, 10)), endDate: formatDateToDisplay(item?.endDate?.slice(0, 10)) })),
          education: responseData.educationDetails.map((item) => ({ ...item, startDate: formatDateToDisplay(item?.startDate?.slice(0, 10)), endDate: formatDateToDisplay(item?.endDate?.slice(0, 10)) })),
        }
        setUser(revisedResponseData)
        setInitialData(revisedResponseData)
      } else {
        toast(<ToastError message={`${response.message}, Fill in your details.`} />, toastConfig)
      }
      setLoading(false)
      // setUser(data);
    }

    fetchUserData()
  }, [])

  const handleEditClick = () => {
    setIsEditable(!isEditable)
  }

  const handleChange = (event, section = null, index = null) => {
    const { name, value, type, checked } = event.target

    if (section && index !== null) {
      const newUser = { ...user }
      newUser[section][index][name] = type === "checkbox" ? checked : value
      setUser(newUser)
    } else if (section) {
      if (section === "experiences") {
        setNewExperience({ ...newExperience, [name]: type === "checkbox" ? checked : value })
      } else if (section === "education") {
        setNewEducation({ ...newEducation, [name]: type === "checkbox" ? checked : value })
      }
    } else {
      setUser({ ...user, [name]: type === "checkbox" ? checked : value })
    }
  }

  const handleAddExperience = () => {
    setUser({ ...user, experiences: [...user.experiences, newExperience] })
    setNewExperience({})
  }

  const handleAddEducation = () => {
    setUser({ ...user, education: [...user.education, newEducation] })
    setNewEducation({})
  }
  const handleAddSkill = () => {
    if (!currSkill) {
      alert("Enter Skill First")
      return
    }
    if (currSkill?.trim() !== "") {
      setUser({ ...user, skills: [...user.skills, currSkill.trim()] })

      setCurrSkill("")
    }
  }
  const removeSkill = (skillItem) => {
    const currSkills = user.skills
    const newSkills = currSkills.filter((skill) => skill !== skillItem)
    setUser({ ...user, skills: newSkills })
  }

  const removeExperience = (index) => {
    const newExperiences = user.experiences.filter((exp, expIndex) => expIndex !== index)
    setUser({ ...user, experiences: newExperiences })
  }

  const removeEducation = (index) => {
    const newEducation = user.education.filter((edu, eduIndex) => eduIndex !== index)
    setUser({ ...user, education: newEducation })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    const validLinkedIn = user.linkedin.length > 0 ? validateLinkedInUrl(user.linkedin) : true
    const validWebsite = user.website.length > 0 ? isValidUrl(user.website) : true
    const validGithub = user.github.length > 0 ? validateGitHubUrl(user.github) : true

    !validLinkedIn && toast(<ToastError message={"Invalid Linkedin URL"} />, toastConfig)
    !validWebsite && toast(<ToastError message={"Invalid Website URL"} />, toastConfig)
    !validGithub && toast(<ToastError message={"Invalid Github URL"} />, toastConfig)
    if (!(validGithub && validLinkedIn && validWebsite)) return
    const userData = user
    if (JSON.stringify(user) === JSON.stringify(initialData)) {
      toast(<ToastError message={"No changes made to update"} />, toastConfig)
      return
    }
    const response = await updateBasicInfo({ userData })
    if (response.status === 200) {
      toast(<ToastSuccess message={"Profile Updated Successfully"} />, toastConfig)
    } else {
      toast(<ToastError message={response.message || "Error while updating profile"} />, toastConfig)
    }

    setIsEditable(false)
  }
  const formatDateToDisplay = (isoDateString) => {
    if (!isoDateString) return ""
    const [year, month, day] = isoDateString.split("-")
    return `${year}-${month}-${day}`
  }

  return loading ? (
    <Loader />
  ) : (
    <div className="animate__animated animate__fadeIn">
      <div className="mb-4">
        <div className="flex flex-wrap justify-between items-baseline gap-3">
          {/* Welcome Message */}
          <h2 className="font-semibold text-[#e5e7eb] text-lg sm:text-xl">
            Welcome, {" "}
            <span>{name} </span>
            <VerifiedIcon className="text-blue-500 !text-base" />
          </h2>

          {/* Buttons Container */}
          <div className="flex flex-wrap items-center gap-2 sm:gap-4">
            <Button
              style={{ fontFamily: "inherit" }}
              variant="outlined"
              className="
                     !font-medium hover:!text-yellow-600 hover:!bg-transparent hover:border-yellow-600 
                     !rounded-lg !text-sm !px-2 !py-1.5 !capitalize !text-gray-400 !border-gray-600"
              endIcon={
                isEditable ? (
                  <FontAwesomeIcon className="!text-sm" icon={faCancel} />
                ) : (
                  <FontAwesomeIcon className="!text-sm" icon={faEdit} />
                )
              }
              onClick={handleEditClick}>
              {isEditable ? "Cancel" : "Edit"}
            </Button>

            {isEditable && (
              <Button
                variant="outlined"
                style={{ fontFamily: "inherit" }}
                className="!font-medium hover:!text-yellow-600 hover:!bg-transparent hover:border-yellow-600 
                     !rounded-lg !text-sm !px-2 !py-1.5 !capitalize !text-gray-400 !border-gray-600"
                onClick={handleSubmit}>
                Update
                <FontAwesomeIcon icon={faUpload} style={{ height: "14px" }} />
              </Button>
            )}

          </div>
        </div>
      </div>


      <div className="my-2">
        <div>
          <h4 className="font-medium mb-3 text-base text-[#e5e7eb]">Bio</h4>

          <div>
            {/* First Row: Name, Gender, Birthday */}
            <div className="flex flex-wrap gap-4">
              <fieldset className="profile-fieldset flex-1 min-w-[250px]">
                <legend className="profile-legend">Name</legend>
                <input
                  type="text"
                  className="profile-input input mb-3 w-full"
                  placeholder="Name"
                  name="name"
                  value={name || ""}
                  disabled={true}
                />
              </fieldset>
              <fieldset className="profile-fieldset flex-1 min-w-[250px]">
                <legend className="profile-legend">Gender</legend>
                <select
                  className="drop-down-container profile-input input mb-3 w-full"
                  name="gender"
                  disabled={!isEditable}
                  value={user.gender || "None"}
                  onChange={handleChange}
                >
                  <option defaultValue="None" disabled selected className="option-hide bg-[#343839]">
                    None
                  </option>
                  {genders.map((gender) => (
                    <option key={gender} value={gender} className="bg-[#343839]">
                      {gender}
                    </option>
                  ))}
                </select>
              </fieldset>
              <fieldset className="profile-fieldset flex-1 min-w-[250px]">
                <legend className="profile-legend">Birthday</legend>
                <input
                  type="date"
                  className="profile-input input mb-3 w-full"
                  name="birthday"
                  value={user.birthday || ""}
                  disabled={!isEditable}
                  onChange={handleChange}
                />
              </fieldset>
            </div>

            {/* Second Row: LinkedIn & GitHub */}
            <div className="flex flex-wrap gap-4">
              <fieldset className="profile-fieldset flex-1 min-w-[250px]">
                <legend className="profile-legend">LinkedIn URL</legend>
                <input
                  type="url"
                  placeholder={isEditable ? "LinkedIn URL" : ""}
                  className="profile-input input mb-3 w-full"
                  name="linkedin"
                  value={user.linkedin || ""}
                  disabled={!isEditable}
                  onChange={handleChange}
                />
              </fieldset>
              <fieldset className="profile-fieldset flex-1 min-w-[250px]">
                <legend className="profile-legend">GitHub URL</legend>
                <input
                  type="url"
                  placeholder={isEditable ? "GitHub URL" : ""}
                  className="profile-input input mb-3 w-full"
                  name="github"
                  value={user.github || ""}
                  disabled={!isEditable}
                  onChange={handleChange}
                />
              </fieldset>
            </div>

            {/* Third Row: Website & Location */}
            <div className="flex flex-wrap gap-4">
              <fieldset className="profile-fieldset flex-1 min-w-[250px]">
                <legend className="profile-legend">Website/Portfolio</legend>
                <input
                  type="url"
                  placeholder={isEditable ? "Website" : ""}
                  className="profile-input input mb-3 w-full"
                  name="website"
                  value={user.website || ""}
                  disabled={!isEditable}
                  onChange={handleChange}
                />
              </fieldset>
              <fieldset className="profile-fieldset flex-1 min-w-[250px]">
                <legend className="profile-legend">Location</legend>
                <input
                  type="text"
                  placeholder={isEditable ? "Location" : ""}
                  className="profile-input input mb-3 w-full"
                  name="location"
                  value={user.location || ""}
                  disabled={!isEditable}
                  onChange={handleChange}
                />
              </fieldset>
            </div>

            {/* Summary */}
            <fieldset className="profile-fieldset">
              <legend className="profile-legend">Summary</legend>
              <textarea
                placeholder={isEditable ? "Summary" : " "}
                className="profile-input input mb-3 w-full"
                name="summary"
                value={user?.summary || "No Summary"}
                disabled={!isEditable}
                onChange={handleChange}
              ></textarea>
            </fieldset>
          </div>
        </div>
      </div>

      <LineDivider />

      <div className="my-2">
        <div>
          <h4 className="font-medium mb-3 text-base text-[#e5e7eb]">Technical Skills</h4>

          <fieldset className="profile-fieldset">
            {/* Skills List */}
            <div className="flex flex-wrap gap-2 mb-2">
              {user.skills &&
                user.skills.map((skill) => (
                  <Button
                    key={skill}
                    style={{ fontFamily: "inherit" }}
                    disabled={!isEditable}
                    variant="outlined"
                    className="!font-normal !text-sm !px-2 !py-1 !capitalize !text-gray-200 !rounded-lg !border-gray-600"
                    endIcon={isEditable ? <Cancel /> : ""}
                    onClick={() => removeSkill(skill)}
                  >
                    {skill}
                  </Button>
                ))}
            </div>

            {/* Add Skill Input */}
            {isEditable && (
              <div className="flex flex-wrap sm:flex-nowrap items-center gap-2 mb-2">
                <input
                  type="text"
                  placeholder="Technical Skills"
                  className="profile-input input w-full sm:w-auto"
                  name="skills"
                  value={currSkill}
                  disabled={!isEditable}
                  onChange={(e) => setCurrSkill(e.target.value)}
                />
                <Button
                  variant="outlined"
                  style={{ fontFamily: "inherit" }}
                  className="!font-medium hover:!text-yellow-600 hover:!bg-transparent hover:border-yellow-600 !rounded-full !text-sm !px-3 !py-1.5 !capitalize !text-gray-400 !border-gray-600"
                  endIcon={<AddCircleOutlineRounded />}
                  onClick={handleAddSkill}
                >
                  Add
                </Button>
              </div>
            )}
          </fieldset>
        </div>
      </div>


      <LineDivider />

      <div className="my-2">
        <div>
          <div className="flex flex-wrap justify-between items-center gap-2">
            <h4 className="font-medium mb-3 text-base text-[#e5e7eb]">Work Experience</h4>
            {isEditable && (
              <Button
                variant="outlined"
                style={{ fontFamily: "inherit" }}
                className="!font-medium hover:!text-yellow-600 hover:!bg-transparent hover:border-yellow-600 !rounded-full !text-sm !px-3 !py-1.5 !capitalize !text-gray-400 !border-gray-600"
                endIcon={<AddCircleOutlineRounded />}
                onClick={() => setNewExperience({ company: "", designation: "", startDate: "", endDate: "", isCurrentCompany: false })}
              >
                Add
              </Button>
            )}
          </div>

          {user.experiences && user.experiences.map((experience, index) => 
          (
            <div key={index} className="flex flex-wrap justify-between gap-4 mt-2">
              <div className="flex-1">
                <div className="flex flex-wrap sm:flex-nowrap gap-4">
                  <fieldset className="flex-1">
                    <legend className="profile-legend">Company</legend>
                    <input
                      type="text"
                      placeholder="Company"
                      className="profile-input input mb-3 w-full"
                      name="company"
                      value={experience?.company || ""}
                      disabled={!isEditable}
                      onChange={(e) => handleChange(e, "experiences", index)}
                    />
                  </fieldset>
                  <fieldset className="flex-1">
                    <legend className="profile-legend">Designation/Title</legend>
                    <input
                      type="text"
                      placeholder="Designation"
                      className="profile-input input mb-3 w-full"
                      name="title"
                      value={experience?.designation || ""}
                      disabled={!isEditable}
                      onChange={(e) => handleChange(e, "experiences", index)}
                    />
                  </fieldset>
                </div>

                <div className="flex flex-wrap sm:flex-nowrap gap-4 items-baseline">
                  <fieldset className="flex-1">
                    <legend className="profile-legend">Start Date</legend>
                    <input
                      type="date"
                      className="profile-input input mb-3 w-full"
                      name="startDate"
                      value={experience?.startDate || ""}
                      disabled={!isEditable}
                      onChange={(e) => handleChange(e, "experiences", index)}
                    />
                  </fieldset>

                  <fieldset className="flex-1">
                    <legend className="profile-legend">End Date</legend>
                    <input
                      type="date"
                      className="profile-input input mb-3 w-full"
                      name="endDate"
                      value={experience?.endDate || ""}
                      disabled={!isEditable}
                      onChange={(e) => handleChange(e, "experiences", index)}
                    />
                  </fieldset>
                </div>
              </div>

              {isEditable && (
                <button
                  disabled={!isEditable}
                  onClick={() => removeExperience(index)}
                  className="rounded-full text-gray-400 border-gray-600 hover:text-yellow-600"
                >
                  <Delete />
                </button>
              )}
            </div>
          )
          )}

          {user.experiences && user.experiences.length === 0 && !isEditable && (
            <p className="text-gray-400 text-sm">Add your work experience</p>
          )}

          {/* New Experience Form */}
          {isEditable && Object.keys(newExperience).length > 0 && (
            <div className="flex flex-wrap justify-between gap-4 mt-2">
              <div className="flex-1">
                <div className="flex flex-wrap sm:flex-nowrap gap-4">
                  <fieldset className="flex-1">
                    <legend className="profile-legend">Company Name</legend>
                    <input
                      type="text"
                      placeholder="Company"
                      className="profile-input input mb-3 w-full"
                      name="company"
                      value={newExperience.company || ""}
                      onChange={(e) => handleChange(e, "experiences")}
                    />
                  </fieldset>
                  <fieldset className="flex-1">
                    <legend className="profile-legend">Designation/Title</legend>
                    <input
                      type="text"
                      placeholder="Designation"
                      className="profile-input input mb-3 w-full"
                      name="designation"
                      value={newExperience.designation || ""}
                      onChange={(e) => handleChange(e, "experiences")}
                    />
                  </fieldset>
                </div>

                <div className="flex flex-wrap sm:flex-nowrap gap-4 items-baseline">
                  <fieldset className="flex-1">
                    <legend className="profile-legend">Start Date</legend>
                    <input
                      type="date"
                      className="profile-input input mb-3 w-full"
                      name="startDate"
                      value={newExperience.startDate || ""}
                      onChange={(e) => handleChange(e, "experiences")}
                    />
                  </fieldset>

                  <fieldset className="flex-1">
                    <legend className="profile-label">End Date</legend>
                    <input
                      type="date"
                      className="profile-input input mb-3 w-full"
                      name="endDate"
                      value={newExperience.endDate || ""}
                      onChange={(e) => handleChange(e, "experiences")}
                    />
                  </fieldset>
                </div>
              </div>

              <div className="flex flex-col items-center justify-center gap-6">
                <button
                  onClick={handleAddExperience}
                  className="rounded-full text-gray-400 border-gray-600 hover:text-yellow-600"
                >
                  <TaskAlt />
                </button>
                <button
                  onClick={() => setNewExperience({})}
                  className="rounded-full text-gray-400 border-gray-600 hover:text-yellow-600"
                >
                  <Cancel />
                </button>
              </div>
            </div>
          )}
        </div>
      </div>


      <LineDivider />

      <div className="my-2">
        <div>
          <div className="flex justify-between items-baseline flex-wrap">
            <h4 className="font-medium mb-3 text-base text-[#e5e7eb]">Education</h4>
            {isEditable && (
              <Button
                variant="outlined"
                style={{ fontFamily: "inherit" }}
                className="!font-medium hover:!text-yellow-600 hover:!bg-transparent hover:border-yellow-600 
            !rounded-full !text-sm !px-3 !py-1.5 !capitalize !text-gray-400 !border-gray-600"
                endIcon={<AddCircleOutlineRounded />}
                onClick={() => setNewEducation({ institute: "", degree: "", startDate: "", endDate: "", isCurrentInstitute: false })}>
                Add
              </Button>
            )}
          </div>

          {user.education && user.education.map((edu, index) => (
            <div key={index} className="flex flex-wrap items-start gap-4 mt-2">
              <div className="flex-1">
                <div className="flex flex-col sm:flex-row gap-4">
                  <fieldset className="profile-fieldset flex-1">
                    <legend className="profile-legend">Institute</legend>
                    <input
                      type="text"
                      placeholder="Institute"
                      className="profile-input input w-full"
                      name="institute"
                      value={edu.institute || ""}
                      disabled={!isEditable}
                      onChange={(e) => handleChange(e, "education", index)}
                    />
                  </fieldset>
                  <fieldset className="profile-fieldset flex-1">
                    <legend className="profile-legend">Degree</legend>
                    <input
                      type="text"
                      placeholder="Degree"
                      className="profile-input input w-full"
                      name="degree"
                      value={edu.degree || ""}
                      disabled={!isEditable}
                      onChange={(e) => handleChange(e, "education", index)}
                    />
                  </fieldset>
                </div>

                <div className="flex flex-col sm:flex-row gap-4">
                  <fieldset className="profile-fieldset flex-1">
                    <label>Department/Branch</label>
                    <input
                      type="text"
                      placeholder="Department/Branch"
                      className="profile-input input w-full"
                      name="department"
                      value={edu.department || ""}
                      disabled={!isEditable}
                      onChange={(e) => handleChange(e, "education", index)}
                    />
                  </fieldset>
                  <fieldset className="profile-fieldset flex-1">
                    <label className="profile-label">Score</label>
                    <input
                      type="text"
                      placeholder="Score"
                      className="profile-input input w-full"
                      name="score"
                      value={edu.score || ""}
                      disabled={!isEditable}
                      onChange={(e) => handleChange(e, "education", index)}
                    />
                  </fieldset>
                </div>

                <div className="flex flex-col sm:flex-row gap-4">
                  <fieldset className="profile-fieldset flex-1">
                    <label className="profile-label">Start Date</label>
                    <input
                      type="date"
                      className="profile-input input w-full"
                      name="startDate"
                      value={edu.startDate || ""}
                      disabled={!isEditable}
                      onChange={(e) => handleChange(e, "education", index)}
                    />
                  </fieldset>
                  <fieldset className="profile-fieldset flex-1">
                    <label className="profile-label">End Date</label>
                    <input
                      type="date"
                      className="profile-input input w-full"
                      name="endDate"
                      value={edu.endDate || ""}
                      disabled={!isEditable || edu.isCurrentInstitute}
                      onChange={(e) => handleChange(e, "education", index)}
                    />
                  </fieldset>
                </div>
              </div>

              {isEditable && (
                <Button
                  disabled={!isEditable}
                  onClick={() => removeEducation(index)}
                  className="!rounded-full !text-gray-400 !border-[#e5e7eb1a] hover:!text-yellow-600 h-[32px]">
                  <Delete />
                </Button>
              )}
            </div>
          ))}

          {user.education && user.education.length === 0 && !isEditable && (
            <p className="text-gray-400 text-sm">Add your Education</p>
          )}

          {isEditable && newEducation && Object.keys(newEducation).length > 0 && (
            <div className="flex flex-wrap items-start gap-4 mt-2">
              <div className="flex-1">
                <div className="flex flex-col sm:flex-row gap-4">
                  <fieldset className="profile-fieldset flex-1">
                    <legend className="profile-legend">Institute</legend>
                    <input
                      type="text"
                      placeholder="Institute"
                      className="profile-input input w-full"
                      name="institute"
                      value={newEducation.institute || ""}
                      onChange={(e) => handleChange(e, "education")}
                    />
                  </fieldset>
                  <fieldset className="profile-fieldset flex-1">
                    <legend className="profile-legend">Degree</legend>
                    <input
                      type="text"
                      placeholder="Degree"
                      className="profile-input input w-full"
                      name="degree"
                      value={newEducation.degree || ""}
                      onChange={(e) => handleChange(e, "education")}
                    />
                  </fieldset>
                </div>

                <div className="flex flex-col sm:flex-row gap-4">
                  <fieldset className="profile-fieldset flex-1">
                    <legend className="profile-legend">Department/Branch</legend>
                    <input
                      type="text"
                      placeholder="Department/Branch"
                      className="profile-input input w-full"
                      name="department"
                      value={newEducation.department || ""}
                      onChange={(e) => handleChange(e, "education")}
                    />
                  </fieldset>
                  <fieldset className="profile-fieldset flex-1">
                    <legend className="profile-legend">Score</legend>
                    <input
                      type="text"
                      placeholder="Score"
                      className="profile-input input w-full"
                      name="score"
                      value={newEducation.score || ""}
                      onChange={(e) => handleChange(e, "education")}
                    />
                  </fieldset>
                </div>

                <div className="flex flex-col sm:flex-row gap-4">
                  <fieldset className="profile-fieldset flex-1">
                    <legend className="profile-legend">Start Date</legend>
                    <input
                      type="date"
                      className="profile-input input w-full"
                      name="startDate"
                      value={newEducation.startDate || ""}
                      onChange={(e) => handleChange(e, "education")}
                    />
                  </fieldset>
                  <fieldset className="profile-fieldset flex-1">
                    <legend className="profile-legend">End Date</legend>
                    <input
                      type="date"
                      className="profile-input input w-full"
                      name="endDate"
                      value={newEducation.endDate || ""}
                      onChange={(e) => handleChange(e, "education")}
                    />
                  </fieldset>
                </div>
              </div>

              <div className="flex flex-col items-center justify-center gap-6">
                <button onClick={handleAddEducation} className="rounded-full text-gray-400 border-gray-600 hover:text-yellow-600">
                  <TaskAlt />
                </button>
                <button onClick={() => setNewEducation({})} className="rounded-full text-gray-400 border-gray-600 hover:text-yellow-600">
                  <Cancel />
                </button>
              </div>
            </div>
          )}
        </div>
      </div>


    </div>
  )
}

export default BasicInfo
