import { useLocation, Link, Outlet } from "react-router-dom";
import { CircleNotificationsRounded, InfoRounded, AccountCircleRounded, PaymentRounded, MenuRounded, CloseRounded, Quiz } from "@mui/icons-material";
import CommonHeader from "../../assets/common-header";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import UpgradeToPremium from "./UpgradeToPremium";
import { Modal } from "@mui/material";
import DashboardIcon from '@mui/icons-material/Dashboard';
import MapIcon from '@mui/icons-material/Map';
export default function ProfilePage() {
    const location = useLocation();
    const userInfo = useSelector((state) => state.user.userDetails);
    const [primiumPopup, setPrimiumPopup] = useState(false);
    const [sidebarOpen, setSidebarOpen] = useState(false);

    const navOptions = [
        { name: "Basic Info", navigateTo: "", icon: <InfoRounded className="!text-lg" /> },
        { name: "Account", navigateTo: "account", icon: <AccountCircleRounded className="!text-lg" /> },
        { name: "Inbox", navigateTo: "notifications", icon: <CircleNotificationsRounded className="!text-lg" /> },
        { name: "Subscriptions & Orders", navigateTo: "subscriptions", icon: <PaymentRounded className="!text-lg" /> }
    ];

    const mobileNavOptions = [
        { name: "Free Style", navigateTo: "/questions", icon: <Quiz className="!text-lg" /> },
        { name: "Dashboard", navigateTo: "/dashboard", icon: <DashboardIcon className="!text-lg" /> },
        { name: "Arenas", navigateTo: "/arenas", icon: <MapIcon className="!text-lg" /> },
    ]

    useEffect(() => {
        if (!(userInfo?.subscription?.isActive)) {
            setTimeout(() => setPrimiumPopup(true), 2000);
        }
    }, [userInfo]);

    return (
        <>
            <Modal open={primiumPopup} onClose={() => setPrimiumPopup(false)} className="flex justify-center items-center md:hidden">
                <UpgradeToPremium />
            </Modal>
            <CommonHeader />

            {/* Responsive Navbar Toggle Button */}
            <button
                onClick={() => setSidebarOpen(!sidebarOpen)}
                className="md:hidden absolute top-1 right-4 bg-[#1A1A1A] p-2 rounded-full text-white z-[1000]"
            >
                {sidebarOpen ? <CloseRounded /> : <MenuRounded />}
            </button>

            <div className="flex h-[calc(100vh-50px)] bg-[#232627] text-white">

                {/* Sidebar */}
                <aside className={`fixed md:static top-14 left-0 h-full md:h-auto w-64 bg-[#1D1D1D] p-3 rounded-xl m-2 overflow-auto transition-all z-40
                    ${sidebarOpen ? "translate-x-0 border-r border-[#2b2a2a]" : "-translate-x-full"} md:translate-x-0`}>

                    <nav>
                        <ul className="animate__animated animate__fadeInLeft">
                            {navOptions.map((opt, i) => {
                                const isActive = location.pathname === `/profile/${opt.navigateTo}`;
                                return (
                                    <li
                                        key={i}
                                        className={`px-4 py-3 mb-4 text-sm flex gap-2 items-center transition-all rounded-lg 
                                            ${isActive ? "bg-[#333] text-white font-semibold" : "text-gray-400 hover:text-white"}`}
                                    >
                                        {opt.icon}
                                        <Link
                                            to={`/profile/${opt.navigateTo}`}
                                            className="w-full"
                                            onClick={() => setSidebarOpen(false)} // Close on mobile click
                                        >
                                            {opt.name}
                                        </Link>
                                    </li>
                                );
                            })}

                            {
                                mobileNavOptions?.map((opt, index) =>

                                    <li className={`px-4 py-3 mb-4 text-sm text-gray-400 flex gap-2 items-center transition-all rounded-lg sm:flex md:hidden`}>
                                        {opt?.icon}
                                        <Link
                                            to={`${opt?.navigateTo}`}
                                            className="w-full"
                                            onClick={() => setSidebarOpen(false)} // Close on mobile click
                                        >
                                            {opt.name}
                                        </Link>
                                    </li>
                                )
                            }

                        </ul>
                    </nav>

                    {
                        !(userInfo?.subscription?.isActive) &&
                        <div className="hidden sm:hidden md:block animate__animated animate__fadeInLeft">
                            <UpgradeToPremium />
                        </div>
                    }
                </aside>

                {/* Main Content */}
                <main className="flex-1 p-6 rounded-lg bg-[#1D1D1D] m-2 overflow-auto">
                    <Outlet />
                </main>
            </div>
        </>
    );
}
