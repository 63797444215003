import React, { useEffect, useRef, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Link } from "react-router-dom"

import CommonHeader from "../assets/common-header"
import { fetchArenasList } from "./../redux/actions/arena"
import Loader from "../assets/loader"
import { fetchUserDetails } from "../redux/actions/user"
import { toast } from "react-toastify"
import { useNavigate } from "react-router-dom"
import "../assets/css_files/arenas.css"
import PeopleAltRoundedIcon from "@mui/icons-material/PeopleAltRounded"
import LaunchRoundedIcon from "@mui/icons-material/LaunchRounded"
import { NoDataFound } from "../assets/image_files"
import { Tooltip } from "@mui/material"
import { Button } from "@material-ui/core"
import { Paid, ReadMore } from "@mui/icons-material"
import ToastError from "./toasts/ToastError"
import { toastConfig } from "../utils/constants"
import ArenaCard from "./ui/arena/ArenaCard"


function Arenas() {
  const [showArena, setActiveArena] = useState()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { availableArenas, enrolledArenas, userDetails } = useSelector((state) => ({
    availableArenas: state.arena.availableArenas,
    enrolledArenas: state.arena.enrolledArenas,
    userDetails: state.user.userDetails,
  }))

  useEffect(() => {
    dispatch(fetchArenasList())
    dispatch(fetchUserDetails())
  }, [dispatch])

  if (userDetails && !userDetails.isVerified) {
    toast(<ToastError message={"Please verify your email to continue"} />, toastConfig)
    navigate("/verify-email")
  }

  useEffect(() => {
    console.log(availableArenas, "--arenas")
  }, [availableArenas])


  return (
    <div>
      <CommonHeader bg="#1d1d1d" />
      <div className="arenas-homepage-container p-4">

        {/* heading starts */}
        <div className="bg-[#1d1d1d] z-50 py-2 mb-4">
          <h2 className="font-bold text-2xl text-center text-gray-200">Arenas - Pathway to domain expertise</h2>
          <div className="gradient" />
        </div>
        {/* heading ends */}


        <div className="flex gap-4 h-full">

          <div className="enrolled-arenas-container relative w-[25%] h-full overflow-hidden">
            <div className="sticky top-0 bg-white z-10">
              <h2 className="title-line font-normal text-base p-2 text-gray-200">Enrolled Arenas</h2>
            </div>
            <div className="p-2 h-[calc(100vh-12rem)] flex flex-col gap-2 overflow-auto">
              {
                enrolledArenas?.length === 0 && (
                  <div className="flex flex-col justify-center items-center h-full">
                    <img src={NoDataFound} alt="No Data Found" className="w-[150px] mb-3 opacity-35" />
                    <h2 className="text-base text-gray-400">No Enrollments</h2>
                  </div>
                )
              }
              {enrolledArenas?.map((arena) => (
                <ArenaCard key={arena?._id} arena={arena} showDescription={false} />
              ))}
            </div>
          </div>

          <div className="enrolled-arenas-container w-full overflow-auto relative">
            <div
              className="arenas-list-description h-[calc(100vh-12rem)] overflow-auto">
              {
                availableArenas?.length === 0 && (
                  <div className="flex flex-col justify-center items-center h-full">
                    <img src={NoDataFound} alt="No Data Found" className="w-[150px] mb-3 opacity-35" />
                    <h2 className="text-base text-gray-400">No Arenas Available</h2>
                  </div>
                )
              }
              <div className="arenas-avl-list grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-3 overflow-auto p-2">
                {availableArenas?.map((arena) => (
                  <ArenaCard key={arena?._id} arena={arena} />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Arenas
