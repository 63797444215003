import React, { useState } from "react"
import { toast } from "react-toastify"
import { Container, Typography, FormControl, InputLabel, Select, MenuItem, Slider, Button, Stepper, Step, StepLabel, Box, Card, CardContent, createTheme, ThemeProvider } from "@mui/material"
import { SUBJECTS, INDUSTRIES, LEVELS, toastConfig } from "../utils/constants"
import { useNavigate } from "react-router-dom"
import { ArrowBackIos, ArrowForwardIos, NavigateNext, SubjectOutlined } from "@mui/icons-material"
import Dropdown from "./ui/Dropdown"
import ToastWarning from "./toasts/ToastWarning"

const steps = ["Select Tool", "Select Level"]

const theme = createTheme({
  palette: {
    primary: {
      main: "#d69e2e", // yellowish orange
    },
    secondary: {
      main: "#ff4081", // Pink A200
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 20,
          padding: "10px 20px",
        },
      },
    },
  },
  typography: {
    h4: {
      fontWeight: "bold",
      marginBottom: "20px",
      color: "#4a148c",
    },
    h6: {
      color: "#4a148c",
    },
  },
})

const ToolLevelForm = ({ onSubmit }) => {
  const navigate = useNavigate()

  const [activeStep, setActiveStep] = useState(0)
  const [selectedTool, setSelectedTool] = useState(SUBJECTS[0])
  const [level, setLevel] = useState(1)
  const [selectedIndustry, setSelectedIndustry] = useState(INDUSTRIES[0])

  const handleNext = () => setActiveStep((prevActiveStep) => prevActiveStep + 1)
  const handleBack = () => setActiveStep((prevActiveStep) => prevActiveStep - 1)
  const handleToolChange = (val) => setSelectedTool(val)
  const handleSliderChange = (event, newValue) => setLevel(newValue)
  const handleIndustryChange = (event) => setSelectedIndustry(event.target.value)

  const handleSubmit = (event) => {
    event.preventDefault()

    // Submit data to backend or handle as needed
    if (!selectedTool || !level) {
      toast(<ToastWarning message={"Please share all your preferences"} />, toastConfig)
      return
    }
    onSubmit({ tool: selectedTool, level: level })
  }

  return (
    <div className="flex flex-col justify-center items-center h-[calc(100vh-50px)] bg-[#232627]">
      <div className="mx-auto my-4">
        <h2 className="font-bold text-3xl text-gray-200 my-1">Share your preferences</h2>
        <div className="gradient" />
      </div>
      <div className="flex flex-col items-center justify-center w-screen">
        <div className="shiny-border  w-full max-w-md">
          <div className=" myCard p-8 !text-[#e5e7eb] !bg-[#333333]">
            <Stepper
              activeStep={activeStep}
              alternativeLabel>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel StepIconProps={{ sx: { color: "secondary.main" } }}>
                    <Typography className="!text-base !text-gray-200">{label}</Typography>
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
            <form
              onSubmit={handleSubmit}
              className="flex flex-col items-center justify-center w-full">
              {activeStep === 0 && (
                <div className="flex-row-bw-container w-full gap-4 my-5">
                  {SUBJECTS.map((subject) => (
                    <Button
                      variant="outlined"
                      key={subject}
                      className={`!flex-1 !text-gray-400 !border-gray-400 !shadow-none ${selectedTool === subject ? "!text-yellow-600 !border-yellow-600" : ""} `}
                      size="small"
                      onClick={() => handleToolChange(subject)}>
                      {subject}
                    </Button>
                  ))}
                </div>
              )}
              {activeStep === 1 && (
                <Box className="w-full">
                  <Typography
                    id="level-slider"
                    gutterBottom
                    className="!text-gray-200 !my-4">
                    Level (1-{LEVELS})
                  </Typography>
                  <Slider
                    value={level}
                    onChange={handleSliderChange}
                    aria-labelledby="level-slider"
                    valueLabelDisplay="auto"
                    step={1}
                    marks
                    min={1}
                    max={LEVELS}
                    className="!text-gray-200"
                  />
                  <Button
                    variant="outlined"
                    size="small"
                    disabled
                    className="hover:!bg-transparent !font-medium !text-xs !px-2 !py-1.5 !capitalize !my-3 !rounded-none
                    !border-0  !border-b !text-gray-400 !border-gray-400 hover:!text-gray-200">
                    Current level: {level}
                  </Button>
                </Box>
              )}
              <div className="w-full flex flex-row items-center justify-center">
                <Button
                  variant="contained"
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  className="!font-medium disabled:!bg-[#4C4C4C] disabled:!text-[#323232] !bg-white !text-black !capitalize"
                  size="small"
                  startIcon={<ArrowBackIos className="!text-sm"/>}
                  >
                  Back
                </Button>
                <div className="flex-1" />
                {activeStep === steps.length - 1 ? (
                  <Button
                    onClick={handleSubmit}
                    className="!text-black !bg-[#B1FF59] !shadow-none !capitalize"
                    size="small">
                    Finish
                  </Button>
                ) : (
                  <Button
                    onClick={handleNext}
                    variant="contained"
                    className=" !text-black !bg-[#B1FF59] !shadow-none !capitalize"
                    size="small"
                    endIcon={<ArrowForwardIos className="!text-sm" />}
                    >
                    Next
                  </Button>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ToolLevelForm
