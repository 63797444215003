import { LEVELS, SUBJECTS } from "../utils/constants";
import Dropdown from "./ui/Dropdown";
import { Box, Slider, Typography, MenuItem } from '@mui/material';
import { SkipNext, SubjectOutlined } from '@mui/icons-material';
export const QuestionFetchError = ({ preferences, onChange }) => {
    return (
        <div className="px-4">
            <div className="mx-auto my-4">
                <h2 className="font-bold text-3xl text-center text-red-400 mb-3">Oops!</h2>
                <h2 className="font-medium text-xl text-center text-gray-200">We’re having trouble loading the current question</h2>

                <div className="gradient" />
            </div>
            <div className="shiny-border max-w-sm m-auto shadow-lg">
                <div className="myCard p-6 !bg-[#1f2021]">

                    <div id="subject-dropdown">
                        <Dropdown
                            title={preferences.tool || "Tools"}
                            tooltip={`Primary tool selected for the situation is ${preferences.tool}`}
                            icon={<SubjectOutlined />}
                            children={SUBJECTS.map((subject) => (
                                <MenuItem
                                    key={subject}
                                    onClick={() => {
                                        subject !== preferences.tool && onChange("toolUpdate", subject)
                                    }}
                                    className="!text-gray-200 !font-light !text-xs">
                                    {subject}
                                </MenuItem>
                            ))}
                        />
                    </div>
                    <Box className="w-full !my-4">
                        <div className="flex justify-between items-center mb-2">
                            <Typography
                                id="level-slider"
                                gutterBottom
                                className="!text-gray-200 !m-0">
                                Level (1-{LEVELS})
                            </Typography>
                            <p className="font-medium text-sm">
                                <span className="text-gray-400 font-normal"> Current level: </span>{preferences.level}
                            </p>
                        </div>
                        <Slider
                            value={preferences.level}
                            onChange={(event) => onChange("levelUpdate", event.target.value)}
                            aria-labelledby="level-slider"
                            valueLabelDisplay="auto"
                            step={1}
                            marks
                            min={1}
                            max={LEVELS}
                            className="!text-gray-400"
                        />
                    </Box>

                    <button
                        className={`cursor-pointer flex items-center hover:bg-[#313435] rounded-md transition-all p-2 bg-[#343839]`}
                        onClick={() => onChange("next")}>
                        <SkipNext className={`text-gray-200 !text-base`} />
                        <span className={`!text-gray-200 text-sm`}>
                            Switch to Next Question
                        </span>
                    </button>
                </div>
            </div>
        </div>
    );
};
