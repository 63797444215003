import React, { useEffect, useState } from "react";
import rehypeAutolinkHeadings from "rehype-autolink-headings";
import rehypeSlug from "rehype-slug";
import rehypeDocument from "rehype-document";
import rehypeFormat from "rehype-format";
import rehypeStringify from "rehype-stringify";
import remarkParse from "remark-parse";
import remarkRehype from "remark-rehype";
import { unified } from "unified";
import rehypePrettyCode from "rehype-pretty-code";
import { transformerCopyButton } from "@rehype-pretty/transformers";

const SituationMarkdown = ({ markdown }) => {
    const [content, setContent] = useState("");
    console.log(markdown)
    const processor = unified()
        .use(remarkParse)
        .use(remarkRehype)
        .use(rehypeDocument, { title: "👋🌍" })
        .use(rehypeFormat)
        .use(rehypeStringify)
        .use(rehypeSlug)
        .use(rehypeAutolinkHeadings)
        .use(rehypePrettyCode, {
            theme: "github-dark",
            transformers: [
                transformerCopyButton({
                    visibility: "always",
                    feedbackDuration: 3000,
                }),
            ],
        });

    const htmlContent = async () => {
        return (await processor.process(markdown)).toString();
    };

    useEffect(() => {
        const processMarkdown = async () => {
            const data = await htmlContent();
            console.log(data)
            setContent(data);
        };

        processMarkdown();
    }, [markdown]);

    return (
        <div className="prose dark:prose-invert max-w-3xl mx-auto text-sm">
            <div
                dangerouslySetInnerHTML={{ __html: content }}
                className="[&>h1]:text-base [&>h2]:text-base [&>h3]:text-sm 
                 [&>h4]:text-sm [&>h4]:mt-2 [&>h5]:text-sm [&>h6]:text-sm 
                 [&>ul]:list-none [&>ul>li]:p-0 [&>ul>li]:my-0 [&>ul>li]:font-mono [&>ul>li]:text-gray-400 [&>ul>li]:text-[.7rem] [&>ul]:my-2
                 [&>p]:my-2 [&>p]:px-2 [&>h5]:my-2 [&>h5]:font-semibold [&>h5]:mt-4
                 [&>h6]:px-2 [&>h6]:mt-4 [&>h6]:font-semibold
                 [&>li]:text-gray-700"
            />
        </div>
    );
};

export default SituationMarkdown;
