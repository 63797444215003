import React from 'react'
import { FreeStyleImage } from '../../assets/image_files'
import Carroussel from './carousel/Carousel'

const FreeStyleContent = () => {
    return (
        <div className='mx-auto flex flex-col md:flex-row w-full justify-center items-center my-10 gap-6'>

            <div className='w-full md:w-[70%]'>
                <h3
                    className='text-xl font-medium mb-4 text-center'
                    style={{
                        backgroundImage: "linear-gradient(96deg, #dddcff, #c1bfff)",
                        WebkitTextFillColor: "transparent",
                        WebkitBackgroundClip: "text"
                    }}
                    fontFamily="inherit"
                >
                    Free Style
                </h3>

                <Carroussel
                    width="90%"
                    margin="0 auto"
                    offset={2}
                    showArrows={false}
                />
            </div>

            <div className='flex-1 text-white'>
                <img
                    src={FreeStyleImage}
                    alt="free style image"
                    className='max-w-[350px] w-full m-auto drop-shadow-[0_0_20px_rgba(156,62,249,0.5)]'
                />
            </div>
        </div>

    )
}

export default FreeStyleContent