import { useRef, useState, useEffect } from "react";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import "./Tags.module.css";

const Tags = ({ list }) => {
    const elementRef = useRef(null);
    const [leftDisabled, setLeftDisabled] = useState(true);
    const [rightDisabled, setRightDisabled] = useState(false);

    useEffect(() => {
        const element = elementRef.current;
        if (!element) return;

        const updateButtonState = () => {
            setLeftDisabled(element.scrollLeft <= 0);
            setRightDisabled(element.scrollLeft + element.clientWidth >= element.scrollWidth);
        };

        element.addEventListener("scroll", updateButtonState);
        updateButtonState(); // Initial check

        return () => element.removeEventListener("scroll", updateButtonState);
    }, []);

    const handleHorizontalScroll = (direction) => {
        if (!elementRef.current) return;
        elementRef.current.scrollBy({ left: direction * 100, behavior: "smooth" });
    };

    return (

        <div className="relative flex items-center">
            {/* Left Button */}
            <button
                onClick={(e) => {
                    e.stopPropagation();
                    handleHorizontalScroll(-1);
                }}
                disabled={leftDisabled}
                className={`absolute left-0 z-50 disabled:opacity-50`}
            >
                <ChevronLeft />
            </button>

            <div className="w-full inline-flex flex-nowrap overflow-hidden 
        [mask-image:_linear-gradient(to_right,transparent_0,_black_100px,_black_calc(100%-50px),transparent_100%)]">
                {/* Scrollable List */}
                <ul
                    className="flex gap-3 overflow-x-auto whitespace-nowrap text-xs px-10"
                    style={{ scrollbarWidth: "none" }}
                    ref={elementRef}
                >
                    {list?.map((item, index) => (
                        <li key={index} className="px-3 py-1 text-gray-400">
                            {item}
                        </li>
                    ))}
                </ul>
            </div>

            {/* Right Button */}
            <button
                onClick={(e) => {
                    e.stopPropagation();
                    handleHorizontalScroll(1);
                }}
                disabled={rightDisabled}
                className={`absolute right-0 z-50 disabled:opacity-50`}
            >
                <ChevronRight />
            </button>
        </div>
    );
};

export default Tags;
