import React, { useEffect, useState, useCallback } from "react"
import { useSelector, useDispatch } from "react-redux"
import Timeline from "@mui/lab/Timeline"
import TimelineItem from "@mui/lab/TimelineItem"
import TimelineSeparator from "@mui/lab/TimelineSeparator"
import TimelineConnector from "@mui/lab/TimelineConnector"
import TimelineContent from "@mui/lab/TimelineContent"
import TimelineDot from "@mui/lab/TimelineDot"
import { useNavigate } from "react-router-dom"
import Modal from "../../../assets/modal"
import { Button } from "@material-ui/core"
import { getRoadmapQuestions } from "../../../utils/apis"
import { toast } from "react-toastify"
import { toastConfig } from "../../../utils/constants"
import { fetchCurrentRoadmapQuestions } from "../../../redux/actions/arena"
import MarqueeScrollTags from "./MarqueeScrollTags"
import Tags from "./Tags"


const RoadMapTimeline = ({ arenaName, roadmapName, roadmap, isUserEnrolled, handlejoin }) => {
  const [isOpen, setIsOpen] = useState(false)
  const colors = { correct: "#02B128", attempted: "#EAB3084a", visited: "#ccc9c94a", "not visited": "#343839" }
  const getColor = (itemStatus) => {
    return colors[itemStatus]
  }
  const [roadmapQuestions, setRoadmapQuestions] = useState([])
  const dispatch = useDispatch()
  const { currentRoadmapQuestions } = useSelector(
    useCallback(
      (state) => ({

        current: state.arena.currentRoadmapQuestions,
      }),
      []
    )
  )

  const fetchroadmapQuestions = async () => {
    try {
      const response = await dispatch(fetchCurrentRoadmapQuestions(arenaName, roadmap.name, roadmap.description))

      if (response.status === 200) {
        setRoadmapQuestions(response.data.questionStatements)
      }
    } catch (e) {
      toast.error(e, toastConfig)
    }
  }

  useEffect(() => {
    fetchroadmapQuestions()
  }, [])

  const navigate = useNavigate()
  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        title="Enrollment Required">
        <p className="text-gray-400 text-sm mt-2">To solve the questions, you first need to enroll in the {arenaName} Arena.</p>
        <div className="flex gap-2 mt-4">
          <Button
            variant="contained"
            size="small"
            onClick={handlejoin}
            className="!shadow-none !bg-gray-600 !text-gray-200 !border-0">
            Enroll
          </Button>
        </div>
      </Modal>
      {
        roadmapQuestions?.length <= 0 && <p className={`font-normal text-gray-200 text-left cursor-auto text-sm my-3`}>No Questions Available</p>

      }
      <Timeline
        position="alternate-reverse"
        className="!p-0 overflow-hidden">
        {roadmapQuestions?.map((question, index) => {
          return (
            <TimelineItem key={index}>
              <TimelineSeparator className="!m-2">
                <TimelineDot sx={{ bgcolor: getColor(question.status) }} />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent
                className="!text-gray-300 hover:bg-[#232627] hover:bg-opacity-70 rounded-md transition-all cursor-pointer w-[20%]"
                onClick={() => {
                  if (isUserEnrolled) {
                    navigate(`/arena-question/${arenaName}/${roadmapName}?questionId=${question.questionId}`, { state: { parentQuestionId: question?.parentQuestionId, status: question?.status } })
                  } else {
                    setIsOpen(true)
                  }
                }}>

                <p className='text-sm leading-6 line-clamp-3 text-left'>{question.ProblemStatement}</p>


                <div className="mt-3 overflow-hidden">
                  {/* <MarqueeScrollTags list={[...question.SQLFunctions, ...question.ExcelFunctions, ...question.PythonFunctions]} /> */}
                  <Tags list={[...question.SQLFunctions, ...question.ExcelFunctions, ...question.PythonFunctions]}/>
                </div>
              </TimelineContent>
            </TimelineItem>
          )
        })}
      </Timeline>
    </>
  )
}

export default RoadMapTimeline
